import React from 'react'
import EditNewsTags from './EditNewsTags/EditNewsTags'

function EditTags() {
    return (

        <main>
            <h2 className="EditContent EditContent__title">Etiquetas</h2>
            <EditNewsTags />
        </main>
    )
}

export default EditTags
