import React, {useState, useEffect} from 'react'
import {Helmet} from 'react-helmet'

import DeleteWorkWithUsItem from './DeleteWorkWithUsItem/DeleteWorkWithUsItem'
import {getWorkWithUsInfo} from '../../../services/ApiClient'
import {JSONToCSVConvertor} from '../../../helpers/globals'
import Loader from '../../Loader/Loader'
import './AdminWorkWithUsPage.css'


function AdminWorkWithUsPage() {


    const [workInfo, setWorkInfo] = useState([])
    const [search, setSearch] = useState('')
    const [bool, setBool] = useState(false)
    const [card, setCard] = useState([])
    const [loading, setLoading] = useState(true)

    const filteredCards = workInfo.filter(card => {
        return (
            card.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
            card.lastname.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
            card.country.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
            card.city.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        )
    })

    const handleChange = (e) => {
        setSearch(e.target.value)
    }

    const showModal = (workItem) => {
        setCard(workItem)
        setBool(!bool)
    }

    const hideModal = () => {
        setBool(!bool)
    }

    const updateCardsData = async () => {
        const allWorkWithUs = await getWorkWithUsInfo()
        setWorkInfo(allWorkWithUs)
    }

    useEffect(() => {
        const fetchData = async () => {
            const allWorkWithUs = await getWorkWithUsInfo()
            setWorkInfo(allWorkWithUs)
            setLoading(false)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loader message="Cargando mensajes..." />}
            <Helmet>
                <title>Grupo LETI | Administrador Formulario Productos</title>
            </Helmet>
            <main className="container-fluid AdminWorkWithUsPage">
                {bool && <DeleteWorkWithUsItem card={card} hideModal={(info) => hideModal(info)} data={updateCardsData} />}
                <div className="row">
                    <div className="col-12 AdminWorkWithUsPage__bg">
                        <div className="container">
                            <input type="text" className="form-control AdminWorkWithUsPage__search" placeholder="Filtrar por nombre, apellido, país o ciudad del candidato" onChange={handleChange} value={search} />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <button className="AdminWorkWithUsPage__download" onClick={() => JSONToCSVConvertor(filteredCards, "Formulario de Talento Humano", true)}
                            >Descargar reporte</button>
                        </div>
                    </div>
                    <div className="row">
                        {filteredCards.length === 0 ?
                            <h1 className="col-12 loader">Sin <span>resultados</span></h1> :
                            filteredCards.map(workItem =>
                                <div className="col-sm-4">
                                    <div className="card" key={workItem.id}>
                                        <div className="card-body">
                                            <span onClick={() => showModal(workItem)} className="AdminWorkWithUsPage__delete"></span>
                                            <span className="AdminWorkWithUsPage__date">{new Date(workItem.createdAt).getDate()} / {new Date(workItem.createdAt).getMonth() + 1} / {new Date(workItem.createdAt).getFullYear()}
                                            </span>
                                            <p className="AdminWorkWithUsPage__medicine">{workItem.name} {workItem.lastname}</p>
                                            <div className="AdminWorkWithUsPage__info">
                                                <p className="AdminWorkWithUsPage__datainfo">
                                                    <strong>Teléfono</strong> {workItem.phone}</p>
                                                <p className="AdminWorkWithUsPage__datainfo">
                                                    <strong>País / Ciudad</strong> {workItem.country} / {workItem.city}</p>
                                                {
                                                    workItem.linkedin && <p className="AdminWorkWithUsPage__datainfo">
                                                        <strong>Perfil de Linkedin</strong> {workItem.linkedin}</p>
                                                }
                                                <p className="AdminWorkWithUsPage__datainfo">
                                                    <strong>Email</strong> <a href={`mailto:${workItem.email}`} className="AdminWorkWithUsPage__email">
                                                        {(workItem.email).toLocaleLowerCase()}
                                                    </a></p>
                                            </div>
                                            <a href={workItem.cv} target="_blank" className="leti-btn" rel="noreferrer">Ver CV</a>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminWorkWithUsPage

