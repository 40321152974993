import React, {useState, useEffect} from 'react'
import {Fade} from "react-awesome-reveal"

import './MarcandoPauta.css'
import {getMarcandoPauta, getUsInfo} from '../../../../services/ApiClient'
import {useAuthContext} from '../../../../contexts/AuthContext'

function MarcandoPauta() {

    const [marcandoPautaData, setMarcandoPautaData] = useState()
    const [age, setAge] = useState('')
    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getMarcandoPautaData = await getMarcandoPauta()
            const getUsInfoData = await getUsInfo()
            setMarcandoPautaData(getMarcandoPautaData)
            setAge(getUsInfoData?.age)

            const counters = document.querySelectorAll('.value')
            const speed = 200

            counters.forEach(counter => {
                const animate = () => {
                    const value = +counter.getAttribute('akhi')
                    const data = +counter.innerText

                    const time = value / speed
                    if (data < value) {
                        counter.innerText = Math.ceil(data + time)
                        setTimeout(animate, 1)
                    } else {
                        counter.innerText = value
                    }

                }

                animate()
            })
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container-fluid MarcandoPauta">
            <div className="row justify-content-between">
                <div className="col-12 col-sm-5 MarcandoPauta__text">
                    <div className="row justify-content-center">
                        <div className="col-11 col-sm-9 offset-sm-5">
                            <Fade direction="down" triggerOnce>
                                <p dangerouslySetInnerHTML={{__html: language === 'ES' ? marcandoPautaData?.description : marcandoPautaData?.description_eng}} />
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 MarcandoPauta__bg" style={{
                    background: `url(${marcandoPautaData?.imgURL}) no-repeat center / contain`,
                }}>
                    {language === 'ES' ? 
                    <div className="MarcandoPauta__absolute">Más de <span className="value" akhi={age}>0</span> años <div className="red-text">marcando la pauta</div></div>
                    :
                    <div className="MarcandoPauta__absolute">More than <span className="value" akhi={age}>0</span> years <div className="red-text">setting standards</div></div>
                    }
                </div>
            </div>
        </section>
    )
}

export default MarcandoPauta

