import './Modified.css'
import React, {useState, useEffect} from 'react'
import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getModified} from '../../../../../services/ApiClient'

function Modified() {

    const [modifiedData, setModifiedData] = useState([])
    const {language} = useAuthContext()

    const parallaxEffect = () => {
        const titleOne = document.querySelector('#title-one')
        const titleTwo = document.querySelector('#title-two')
        const titleThree = document.querySelector('#title-three')
        const getPill = document.querySelector('.BiocontrolledInfo-Modified--image')
        const getBold = document.querySelector('.bold')
        const getLight = document.querySelector('.light')
        const layer = document.querySelector('.BiocontrolledInfo-Modified--wrapper')

        layer.addEventListener('mousemove', function (e) {
            const ivalueX = (e.pageX * -1 / 30)
            const ivalueY = (e.pageY * -1 / 30)
            const cvalueX = (e.pageX * -1 / 40)
            const cvalueY = (e.pageY * -1 / 60)
            const pvalueX = (e.pageX * -1 / 140)
            const pvalueY = (e.pageY * -1 / 160)
            const bvalueX = (e.pageX * -1 / 100)
            const bvalueY = (e.pageY * -1 / 100)
            const lvalueX = (e.pageX * -1 / 80)
            const lvalueY = (e.pageY * -1 / 30)
            
            titleOne.style.transform = `translate3d(${ivalueX}px, ${ivalueY}px, 0)`
            titleTwo.style.transform = `translate3d(${cvalueX}px, ${cvalueY}px, 0)`
            titleThree.style.transform = `translate3d(${cvalueY}px, ${cvalueX}px, 0)`
            getPill.style.transform = `translate3d(${pvalueY}px, ${pvalueX}px, 0)`
            getBold.style.transform = `translate3d(${bvalueX}px, ${bvalueY}px, 0)`
            getLight.style.transform = `translate3d(${lvalueY}px, ${lvalueX}px, 0)`
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            const getModifiedData = await getModified()
            setModifiedData(getModifiedData)
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        parallaxEffect()
    }, [])

    return (
        <>
            <section className="container-fluid BiocontrolledInfo-Modified">
                <div className='row justify-content-center'>
                    <div className='col-sm-8 col-12 BiocontrolledInfo-Modified--wrapper'>
                        <h1 id="title-one">{language === 'ES' ? modifiedData?.title_one : modifiedData?.title_one_eng}</h1>
                        <h1 id="title-two">{language === 'ES' ? modifiedData?.title_two : modifiedData?.title_two_eng}</h1>
                        <h1 id="title-three">{language === 'ES' ? modifiedData?.title_three : modifiedData?.title_three_eng}</h1>
                        <span className='bold' dangerouslySetInnerHTML={{__html: language === 'ES' ? modifiedData?.description : modifiedData?.description_eng}} />
                        <p className='light' dangerouslySetInnerHTML={{__html: language === 'ES' ? modifiedData?.description_two : modifiedData?.description_two_eng}} />
                        <div className='BiocontrolledInfo-Modified--image' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Modified
