import React, {useState, useEffect} from 'react'
import {getFaq, updateFAQInfo} from '../../../../services/ApiClient.js'
import {useFormState} from '../../../../hooks/useFormState.js'
import Button from '../../../Form/FormButton/FormButton.jsx'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel.jsx'

function EditFAQ() {


    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')
    const [faqData, setFaqData] = useState()

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: faqData?.title,
                title_eng: faqData?.title_eng,
                description: faqData?.description,
                description_eng: faqData?.description_eng,
                link: faqData?.link,
                link_eng: faqData?.link_eng,
                link_url: faqData?.link_url,
            },
            error: {
                title: true,
                title_eng: true,
                description: true,
                description_eng: true,
                link: true,
                link_eng: true,
                link_url: true,
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
            link: v => v.length,
            link_eng: v => v.length,
            link_url: v => v.length,
        }
    )

    const {data, error} = state

    const updateFAQ = async (event) => {
        event.preventDefault()
        data.id = faqData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateFAQInfo(data)
                    .then(info => {
                        setFaqData(info[0])
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getFaqData = await getFaq()
            setFaqData(getFaqData[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container-fluid EditContent">
                <h2>Editar textos FAQ</h2>
                <form className="AdminEdit__form" onSubmit={updateFAQ}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data?.title}
                                label="Título"
                                onChange={onChange}
                                name="title"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={faqData?.title}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data?.title_eng}
                                label="Title"
                                onChange={onChange}
                                name="title_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={faqData?.title_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data?.description}
                                label="Descripción"
                                onChange={onChange}
                                name="description"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={faqData?.description}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data?.description_eng}
                                label="Description"
                                onChange={onChange}
                                name="description_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={faqData?.description_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data?.link}
                                label="Texto botón"
                                onChange={onChange}
                                name="link"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={faqData?.link}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data?.link_eng}
                                label="Button text"
                                onChange={onChange}
                                name="link_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={faqData?.link_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data?.link_url}
                                label="Correo de contacto / Contact email"
                                onChange={onChange}
                                name="link_url"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={faqData?.link_url}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Button type="submit" cssStyle="leti-btn">Editar título</Button>
                            {message && <span className="AdminEdit__message ">{message}</span>}
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditFAQ