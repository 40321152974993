import React, {useState, useEffect} from 'react'
import {getBannerBioletisan, updateBioletisanBannerData} from '../../../../services/ApiClient'
import {Helmet} from 'react-helmet'
import {useFormState} from '../../../../hooks/useFormState.js'
import {app} from '../../../../services/firebase'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel'
import InputFile from '../../../Form/InputFile/InputFile'
import Button from '../../../Form/FormButton/FormButton'

function EditBanner() {

    const [bannerData, setBannerData] = useState()
    const [fileSizeMessage1, setFileSizeMessage1] = useState('')
    const [fileSizeMessage2, setFileSizeMessage2] = useState('')
    const [fileSizeMessage3, setFileSizeMessage3] = useState('')
    const [registerError, setRegisterError] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [message, setMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: bannerData?.title,
                title_eng: bannerData?.title_eng,
                imgURLOne: bannerData?.imgURLOne,
                imgURLTwo: bannerData?.imgURLTwo,
                imgURLThree: bannerData?.imgURLThree,
            },
            error: {
                title: true,
                imgURLOne: true,
                imgURLTwo: true,
                imgURLThree: true,
                title_eng: true,
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            imgURLOne: v => v.length,
            imgURLTwo: v => v.length,
            imgURLThree: v => v.length,
        }
    )

    const {data, error} = state

    const updateBanner = async (event) => {
        event.preventDefault()
        data.id = bannerData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateBioletisanBannerData(data)
                    .then(banner => {
                        setBannerData(banner)
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setMessage('')
            setFileSizeMessage1("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage1('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen uno subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLOne = fileUrl
            setIsDisabled(false)
            error.imgURLOne = false
        }
    }

    const onFileSelected2 = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setMessage('')
            setFileSizeMessage2("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage2('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen dos subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLTwo = fileUrl
            setIsDisabled(false)
            error.imgURLTwo = false
        }
    }

    const onFileSelected3 = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setMessage('')
            setFileSizeMessage3("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage3('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen tres subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLThree = fileUrl
            setIsDisabled(false)
            error.imgURLThree = false
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const getBannerData = await getBannerBioletisan()
            setBannerData(getBannerData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>
            <section className="container-fluid EditContent AdminBioletisanLandingPage">
                <h2>Editar banner</h2>

                <form className="AdminEdit__form" onSubmit={updateBanner}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title}
                                label="Título"
                                onChange={onChange}
                                name="title"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={bannerData?.title}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title_eng}
                                label="Title"
                                onChange={onChange}
                                name="title_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={bannerData?.title_eng}
                                en
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='row'>
                            <div className="col-12 col-sm-4">
                                <div className="col-12 EditElementsModal__img">
                                    <img src={bannerData?.imgURLOne} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={bannerData?.title} />
                                    <InputFile
                                        value={bannerData?.imgURLOne}
                                        onChange={onFileSelected}
                                        id="fileButton"
                                        name="imgURLOne"
                                        type="file"
                                        placeholder={bannerData?.imgURLOne}
                                        label="Imagen uno"
                                    />
                                    {
                                        fileSizeMessage1 &&
                                        <small>{fileSizeMessage1}</small>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="col-12 EditElementsModal__img">
                                    <img src={bannerData?.imgURLTwo} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={bannerData?.imgURLTwo} />
                                    <InputFile
                                        value={bannerData?.imgURLTwo}
                                        onChange={onFileSelected2}
                                        id="fileButton"
                                        name="imgURL"
                                        type="file"
                                        placeholder={bannerData?.imgURLTwo}
                                        label="Imagen dos"
                                    />
                                    {
                                        fileSizeMessage2 &&
                                        <small>{fileSizeMessage2}</small>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="col-12 EditElementsModal__img">
                                    <img src={bannerData?.imgURLThree} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={bannerData?.imgURLThree} />
                                    <InputFile
                                        value={bannerData?.imgURLThree}
                                        onChange={onFileSelected3}
                                        id="fileButton"
                                        name="imgURL"
                                        type="file"
                                        placeholder={bannerData?.imgURLThree}
                                        label="Imagen tres"
                                    />
                                    {
                                        fileSizeMessage3 &&
                                        <small>{fileSizeMessage3}</small>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>

                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditBanner

