import './TechCards.css'
import React, {useState, useEffect} from 'react'

import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getTechCards} from '../../../../../services/ApiClient'

function TechCards() {

    const [techCardsData, setTechCardsData] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getTechCardsData = await getTechCards()
            setTechCardsData(getTechCardsData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container-fluid BiocontrolledInfo-TechCards">
                <div className='row'>
                {techCardsData.map(el => 
                    <div className='col-sm-5 col-10'>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{language === 'ES' ? el?.title : el?.title_eng}</h5>
                                <p className='card-text' dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.description : el?.description_eng}} />
                                <a href={el?.link} className="btn"><span className='arrow' /></a>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default TechCards
