import React, {useState, useEffect} from 'react'
import {useAuthContext} from '../../../../contexts/AuthContext'

import {getCarreras} from '../../../../services/ApiClient'
import Loader from '../../../Loader/Loader'
import ModalWorkWithUs from '../ModalWorkWithUs/ModalWorkWithUs'
import './Careers.css'

function Careers() {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [bool, setBool] = useState(false)
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getCarrerasData = await getCarreras()
            setData(getCarrerasData)
        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loader />}
            {bool && <ModalWorkWithUs hideModal={() => setBool(!bool)} />}
            <section className="container Careers">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-4">
                        <h1>{language === 'ES' ? data?.title : data?.title_eng}</h1>
                        <p dangerouslySetInnerHTML={{__html: language === 'ES' ? data?.description : data?.description_eng}} />
                    </div>
                    <div className="col-12 col-sm-4 d-flex align-items-center">
                        <div className="leti-btn" onClick={() => setBool(!bool)}>{language === 'ES' ? data?.buttonTitle : data?.buttonTitle_eng}</div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Careers
