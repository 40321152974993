import './InfoHeader.css'
import React from 'react'

function InfoHeader() {

    return (
        <section className="container-fluid LandingInfo d-block d-sm-none" style={{background: `url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fform-bioleti.png?alt=media&token=a333f355-6c81-4c70-b7fb-b37b92ffd3d8) no-repeat top 9rem left / cover`}}>
        </section>
    )
}

export default InfoHeader
