import React, {useState, useEffect} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {getSuppliers, updateBannerSuppliers} from '../../../../../services/ApiClient'
import {useFormState} from '../../../../../hooks/useFormState'
import {app} from '../../../../../services/firebase'

import InputFile from '../../../../Form/InputFile/InputFile'
import Button from '../../../../Form/FormButton/FormButton'
import Loader from '../../../../Loader/Loader'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'

function EditProductSupplier() {

    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [titleMessage, setTitleMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const [supplier, setSupplier] = useState('')


    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: supplier?.title,
                title_eng: supplier?.title_eng,
                imgURLFarmatodo: supplier?.imgURLFarmatodo,
                imgURLLocatel: supplier?.imgURLLocatel,
                imgURLFarmadon: supplier?.imgURLFarmadon,
                imgURLFarmabien: supplier?.imgURLFarmabien,
                URLFarmatodo: supplier?.URLFarmatodo,
                URLLocatel: supplier?.URLLocatel,
                URLFarmadon: supplier?.URLFarmadon,
                URLFarmabien: supplier?.URLFarmabien,
            },
            error: {
                title: true,
                title_eng: true,
                imgURLFarmatodo: true,
                imgURLLocatel: true,
                imgURLFarmadon: true,
                imgURLFarmabien: true,
                URLFarmatodo: true,
                URLLocatel: true,
                URLFarmadon: true,
                URLFarmabien: true,
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            imgURLFarmatodo: v => v.length,
            imgURLLocatel: v => v.length,
            imgURLFarmadon: v => v.length,
            imgURLFarmabien: v => v.length,
            URLFarmatodo: v => v.length,
            URLLocatel: v => v.length,
            URLFarmadon: v => v.length,
            URLFarmabien: v => v.length,
        }
    )


    const {data, error} = state
    const [registerError, setRegisterError] = useState(null)

    const updateTitle = async (event) => {
        event.preventDefault()
        data.id = supplier._id

        if (error.title === false || error.title_eng === false || error.imgURLFarmatodo === false || error.imgURLLocatel === false || error.imgURLFarmadon === false || error.imgURLFarmabien === false || error.URLFarmatodo === false || error.URLLocatel === false || error.URLFarmadon === false || error.URLFarmabien === false) {
            try {
                await updateBannerSuppliers(data)
                    .then(info => {
                        setSupplier(info)
                        setTitleMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setTitleMessage('Por favor edite alguno de los campos')
        }

    }

    const handleTitle = (e) => {
        data.title = e.target.getContent()
        error.title = false
    }

    const handleTitleEng = (e) => {
        data.title_eng = e.target.getContent()
        error.title_eng = false
    }

    const onFileSelectedFarmatodo = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLFarmatodo = fileUrl
            setIsDisabled(false)
            error.imgURLFarmatodo = false
        }
    }

    const onFileSelectedLocatel = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLLocatel = fileUrl
            setIsDisabled(false)
            error.imgURLLocatel = false
        }
    }

    const onFileSelectedFarmadon = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLFarmadon = fileUrl
            setIsDisabled(false)
            error.imgURLFarmadon = false
        }
    }

    const onFileSelectedFarmabien = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLFarmabien = fileUrl
            setIsDisabled(false)
            error.imgURLFarmabien = false
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getSupplierrData = await getSuppliers()
            setSupplier(getSupplierrData[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isDisabled && <Loader message="Cargando imagen..." />}
            <section className="container-fluid EditContent">
                <h2>Sección proveedores</h2>
                <form className="AdminEdit__form" onSubmit={updateTitle}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Título proveedores
                            </p>
                            <Editor
                                initialValue={supplier?.title}
                                onChange={handleTitle}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Title suppliers
                            </p>
                            <Editor
                                initialValue={supplier?.title_eng}
                                onChange={handleTitleEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-sm-6 col-3">
                            <p className="AdminEdit__form__label">
                                Farmatodo URL (No olvide el http://)
                            </p>
                            <InputWithLabel
                                value={data?.URLFarmatodo}
                                onChange={onChange}
                                name="URLFarmatodo"
                                type="text"
                                cssStyle="form-control"
                                placeholder={supplier?.URLFarmatodo}
                            />
                        </div>
                        <div className="col-sm-6 col-3">
                            <p className="AdminEdit__form__label">
                                Locatel URL (No olvide el http://)
                            </p>
                            <InputWithLabel
                                value={data?.URLLocatel}
                                onChange={onChange}
                                name="URLLocatel"
                                type="text"
                                cssStyle="form-control"
                                placeholder={supplier?.URLLocatel}
                            />
                        </div>
                        <div className="col-sm-6 col-3">
                            <p className="AdminEdit__form__label">
                                Farmadon URL (No olvide el http://)
                            </p>
                            <InputWithLabel
                                value={data?.URLFarmadon}
                                onChange={onChange}
                                name="URLFarmadon"
                                type="text"
                                cssStyle="form-control"
                                placeholder={supplier?.URLFarmadon}
                            />
                        </div>
                        <div className="col-sm-6 col-3">
                            <p className="AdminEdit__form__label">
                                Farmabien URL (No olvide el http://)
                            </p>
                            <InputWithLabel
                                value={data?.URLFarmabien}
                                onChange={onChange}
                                name="URLFarmabien"
                                type="text"
                                cssStyle="form-control"
                                placeholder={supplier?.URLFarmabien}
                            />
                        </div>
                        <div className="col-12 col-sm-3">
                            <div className="col-12 EditElementsModal__img">
                                <img src={supplier?.imgURLFarmatodo} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={supplier?.title} />
                                <InputFile
                                    value={data?.imgURLFarmatodo}
                                    onChange={onFileSelectedFarmatodo}
                                    id="fileButton"
                                    name="imgURL"
                                    type="file"
                                    label="Imagen Farmatodo"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-3">
                            <div className="col-12 EditElementsModal__img">
                                <img src={supplier?.imgURLLocatel} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={supplier?.title} />
                                <InputFile
                                    value={data?.imgURLLocatel}
                                    onChange={onFileSelectedLocatel}
                                    id="fileButton"
                                    name="imgURL"
                                    type="file"
                                    label="Imagen Locatel"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-3">
                            <div className="col-12 EditElementsModal__img">
                                <img src={supplier?.imgURLFarmadon} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={supplier?.title} />
                                <InputFile
                                    value={data?.imgURLFarmadon}
                                    onChange={onFileSelectedFarmadon}
                                    id="fileButton"
                                    name="imgURL"
                                    type="file"
                                    label="Imagen Farmadon"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-3">
                            <div className="col-12 EditElementsModal__img">
                                <img src={supplier?.imgURLFarmabien} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={supplier?.title} />
                                <InputFile
                                    value={data?.imgURLFarmabien}
                                    onChange={onFileSelectedFarmabien}
                                    id="fileButton"
                                    name="imgURL"
                                    type="file"
                                    label="Imagen Farmabien"
                                />
                            </div>
                        </div>
                        {
                            fileSizeMessage &&
                            <div className="col-12">
                                <small>{fileSizeMessage}</small>
                            </div>
                        }
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn">Guardar cambios</Button>
                            {titleMessage && <span className="AdminEdit__message">{titleMessage}</span>}
                            {message && <span className="AdminEdit__message">{message}</span>}
                            {registerError && <div className="alert alert-danger">{registerError}</div>}
                        </div>
                    </div>
                </form>
            </section>
        </>
    )
}

export default EditProductSupplier
