import React, {useState, useEffect} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {getInfoBannerOurPeople, updateInfoBannerOurPeople} from '../../../../../services/ApiClient'
import {useFormState} from '../../../../../hooks/useFormState'
import InputFile from '../../../../Form/InputFile/InputFile'
import Button from '../../../../Form/FormButton/FormButton'
import {app} from '../../../../../services/firebase'
import Loader from '../../../../Loader/Loader'

function EditBannerOurPeopleInfo() {

    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [imageSuccess, setImageSuccess] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [bannerData, setBannerData] = useState()
    const [message, setMessage] = useState('')

    const {state} = useFormState(
        {
            data: {
                id: '',
                backgroundURL: bannerData?.backgroundURL,
                mainDescription: bannerData?.mainDescription,
                mainDescription_eng: bannerData?.mainDescription_eng,
                item: []
            },
            error: {
                mainDescription: true,
                backgroundURL: true,
                mainDescription_eng: true,
            },
            touch: {},
        },
        {
            mainDescription: v => v.length,
            backgroundURL: v => v.length,
            mainDescription_eng: v => v.length,
            item: v => v.length,
        }
    )

    const {data, error} = state
    const [registerError, setRegisterError] = useState(null)


    const updateInfo = async (event) => {
        event.preventDefault()
        data.id = bannerData._id
        //data.backgroundURL=bannerData?.backgroundURL
        //data.mainDescription=bannerData?.mainDescription
        data.item = bannerData?.item

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateInfoBannerOurPeople(data)
                    .then(() => {
                        setMessage('Data actualizada correctamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const onFileSelected = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setImageSuccess("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.backgroundURL = fileUrl
            setIsDisabled(false)
            error.backgroundURL = false
        }
    }

    const handleDescription = (e) => {
        data[e.target.settings.name] = e.target.getContent()
        error[e.target.settings.name] = false
    }

    useEffect(() => {
        const fetchData = async () => {
            const getBannerData = await getInfoBannerOurPeople()
            setBannerData(getBannerData[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isDisabled && <Loader message="Cargando imagen..." />}
            <section className="container-fluid EditContent">
                <h2>Banner superior</h2>
                <form className="AdminEdit__form" onSubmit={updateInfo}>
                    <div className="row">
                        <div className="col-12 col-sm-7">
                            <div className="col-12 EditElementsModal__img">
                                <img src={bannerData?.backgroundURL} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={bannerData?.desc} />
                                <InputFile
                                    classStyle="mb-0"
                                    value={data?.backgroundURL}
                                    onChange={onFileSelected}
                                    id="fileButton"
                                    name="backgroundURL"
                                    type="file"
                                    placeholder={bannerData?.backgroundURL}
                                />
                                {imageSuccess && <span className="AdminEdit__message mt-1">{imageSuccess}</span>}
                            </div>
                        </div>

                        {
                            fileSizeMessage &&
                            <div className="col-12">
                                <small>{fileSizeMessage}</small>
                            </div>
                        }
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={bannerData?.mainDescription}
                                onChange={handleDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    name: 'mainDescription',
                                    height: 180,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={bannerData?.mainDescription_eng}
                                onChange={handleDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    name: 'mainDescription_eng',
                                    height: 180,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>

                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditBannerOurPeopleInfo
