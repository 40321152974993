import './Banner.css'
import React, {useState, useEffect} from 'react'
import {Fade} from 'react-awesome-reveal'
import {getBannerTech} from '../../../../services/ApiClient'
import Loader from '../../../Loader/Loader'
import {useAuthContext} from '../../../../contexts/AuthContext'

function Banner() {

    const [getData, setGetData] = useState()
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getBannerData = await getBannerTech([])
            setGetData(getBannerData[0])
        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loader />}
            <section className="container-fluid Banner__Tech">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-7 Banner__Tech__title">
                            <Fade cascade duration={600} direction="left" triggerOnce>
                                <h1>{language === 'ES' ? getData?.title : getData?.title_eng}</h1>
                                <h3 duration={600} dangerouslySetInnerHTML={{__html: language === 'ES' ? getData?.description : getData?.description_eng}} />
                            </Fade>
                        </div>
                        <Fade cascade delay={600} triggerOnce>
                            <div className="Banner__Tech__blue parallax" data-speed="-0.08" data-axis="vertical" />
                            <div className="Banner__Tech__bg parallax" data-speed="-.1" data-axis="vertical" style={{
                                background: `url("${getData?.imgURL}") no-repeat center center / cover`
                            }} />
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner
