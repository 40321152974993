import './TechTitle.css'
import React, {useState, useEffect} from 'react'
import {Fade} from 'react-awesome-reveal'

import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getTechTitle} from '../../../../../services/ApiClient'

function TechTitle() {

    const [techTitleData, setTechTitleData] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getTechTitleData = await getTechTitle()
            setTechTitleData(getTechTitleData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container BiocontrolledInfo-TechTitle">
                <div className='row'>
                    <div className='col-sm-6 col-12'>
                        <Fade>
                            <h1>{language === 'ES' ? techTitleData?.title : techTitleData?.title_eng}</h1>
                        </Fade>
                    </div>
                    <div className='col-sm-6 col-12'>
                        <Fade>
                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? techTitleData?.description : techTitleData?.description_eng}} />
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TechTitle
