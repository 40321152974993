import React from 'react'
import {Helmet} from 'react-helmet'
import EditBanner from './EditBanner/EditBanner'
import EditBuyProduct from './EditBuyProduct/EditBuyProduct'
import EditGoals from './EditGoals/EditGoals'
import EditInfo from './EditInfo/EditInfo'
import EditPlaces from './EditPlaces/EditPlaces'
import EditFAQ from './EditFAQ/EditFAQ'
import EditFAQItems from './EditFAQItems/EditFAQItems'
import EditBannerRRSS from './EditBannerRRSS/EditBannerRRSS'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'

import './AdminBioletisanLandingPage.css'

function AdminBioletisanLandingPage() {

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>

            <main className="container-fluid">
                <Link to={{
                    pathname: `/bioletisan`
                }} className="AdminBioletisanLandingPage--link" target="_blank">Ver landing page</Link>
                <EditBanner />
                <EditGoals />
                <EditInfo />
                <EditBuyProduct />
                <EditPlaces />
                <EditFAQ />
                <EditFAQItems />
                <EditBannerRRSS />
            </main>
        </>
    )
}

export default AdminBioletisanLandingPage

