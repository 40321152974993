
import React, {useState, useEffect} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {getGallery, addGalleryData, updateGalleryTitle, createContent} from '../../../../../services/ApiClient'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'
import EditElementsModal from './EditElementsModal/EditElementsModal'
import {useFormState} from '../../../../../hooks/useFormState'
import InputFile from '../../../../Form/InputFile/InputFile'
import Button from '../../../../Form/FormButton/FormButton'
import {app} from '../../../../../services/firebase'
import Loader from '../../../../Loader/Loader'

function EditGallery() {

    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [registerError, setRegisterError] = useState(null)
    const [imageSuccess, setImageSuccess] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [galleryData, setGalleryData] = useState([])
    const [modalData, setModalData] = useState([])
    const [titleMessage, setTitleMessage] = useState('')
    const [message, setMessage] = useState('')
    const [bool, setBool] = useState(false)

    const {state, onBlur, onChange} = useFormState(
        {
            data: {
                id: '',
                mainTitle: galleryData[0]?.mainTitle,
                desc: '',
                title: '',
                imgPath: '',
                imgPath_eng: '',
                mainTitle_eng: galleryData[0]?.mainTitle_eng,
                desc_eng: '',
                title_eng: '',
            },
            error: {
                mainTitle: true,
                desc: true,
                title: true,
                imgPath: true,
                imgPath_eng: true,
                mainTitle_eng: true,
                desc_eng: true,
                title_eng: true,
            },
            touch: {},
        },
        {
            mainTitle: v => v.length,
            desc: v => v.length,
            title: v => v.length,
            imgPath: v => v.length,
            imgPath_eng: v => v.length,
            mainTitle_eng: v => v.length,
            desc_eng: v => v.length,
            title_eng: v => v.length,
        }
    )

    const {data, error} = state

    const showModal = (data) => {
        setModalData(data)
        setBool(!bool)
    }

    const contentData = {
        content: '',
        url: '/somos-leti',
        name: 'Somos Leti',
        type: '',
    }

    const addGalleryItem = async (event) => {
        event.preventDefault()
        data.mainTitle = galleryData[0]?.mainTitle

        if (contentData?.content?.length > 0) {
            contentData.type = `Somos Leti - ${data?.title}`
            createContent(contentData)
        }

        if (error.title === false && error.imgPath === false && error.desc === false && error.title_eng === false && error.imgPath_eng === false && error.desc_eng === false) {
            try {
                await addGalleryData(data)
                    .then(gallery => {
                        setGalleryData(gallery)
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por rellene todos los campos')
        }
    }

    const handleDesc = (e) => {
        data.desc = e.target.getContent()
        contentData.content = e.target.getContent({format: "text"})
        error.desc = false
    }

    const handleDescEng = (e) => {
        data.desc_eng = e.target.getContent()
        contentData.content = e.target.getContent({format: "text"})
        error.desc_eng = false
    }

    const deleteItem = (data) => {
        setGalleryData(data)
        setBool(!bool)
    }

    const onFileSelected = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setImageSuccess('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setImageSuccess('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setImageSuccess("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgPath = fileUrl
            setIsDisabled(false)
            error.imgPath = false
        }
    }

    const onFileSelectedEng = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setImageSuccess('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setImageSuccess('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setImageSuccess("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgPath_eng = fileUrl
            setIsDisabled(false)
            error.imgPath_eng = false
        }
    }

    const updateInfo = async (event) => {
        event.preventDefault()

        if (error.mainTitle === false || error.mainTitle_eng === false) {
            try {
                await updateGalleryTitle(data)
                    .then(info => {
                        setGalleryData(info)
                        setTitleMessage('Título actualizado exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setTitleMessage('Por favor edite el título')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getGalleryData = await getGallery()
            data.id = getGalleryData[0]?._id
            setGalleryData(getGalleryData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isDisabled && <Loader message="Cargando imagen..." />}
            {bool && <EditElementsModal hideModal={() => setBool(!bool)} element={modalData} deleteItem={(updateData) => deleteItem(updateData)} />}
            {galleryData?.length > 0 &&
                <section className="container-fluid EditContent EditContent-timeline">
                    <h2>Editar elemento de la galería</h2>
                    <div className="row justify-content-around">
                        {galleryData?.map(el =>
                            <div className="col-sm-1 col-12 EditCarousel__edit" onClick={() => showModal(el)}>
                                <img className="EditCarousel__img" src={el.imgPath} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={el.imgPath} />
                                <p>{el.title}</p>
                            </div>
                        )}
                    </div>
                </section>}
            <section className="container-fluid EditContent">
                <h2>Galería</h2>
                <form className="AdminEdit__form" onSubmit={updateInfo}>
                    <div className="row">
                        <h3 className="mt-5">Editar título galería</h3>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.mainTitle}
                                label="Título galería"
                                onChange={onChange}
                                name="mainTitle"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={galleryData[0]?.mainTitle}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.mainTitle_eng}
                                label="Gallery title"
                                onChange={onChange}
                                name="mainTitle_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={galleryData[0]?.mainTitle_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Button type="submit" cssStyle="leti-btn">Editar título</Button>
                            {titleMessage && <span className="AdminEdit__message ">{titleMessage}</span>}
                        </div>
                    </div>

                    <hr className="mt-5 mb-5" />

                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
                <form className="AdminEdit__form" onSubmit={addGalleryItem}>
                    <div className="row">
                        <h3>Añadir nuevo elemento a la galería</h3>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Título
                            </p>
                            <InputWithLabel
                                value={data?.title}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="title"
                                type="text"
                                cssStyle="form-control mb-0"
                                placeholder="Ingresa título del elemento"
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Title
                            </p>
                            <InputWithLabel
                                value={data?.title_eng}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="title_eng"
                                type="text"
                                cssStyle="form-control mb-0"
                                placeholder="Add element title"
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Imagen
                            </p>
                            <InputFile
                                value={data?.imgPath}
                                onChange={onFileSelected}
                                id="fileButton"
                                name="picpath"
                                type="file"
                            />
                            {imageSuccess && <span className="AdminEdit__message mt-1">{imageSuccess}</span>}
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Image (for image with text description on it only)
                            </p>
                            <InputFile
                                value={data?.imgPath_eng}
                                onChange={onFileSelectedEng}
                                id="fileButton"
                                name="picpath_eng"
                                type="file"
                            />
                            {imageSuccess && <span className="AdminEdit__message mt-1">{imageSuccess}</span>}
                        </div>
                        {
                            fileSizeMessage &&
                            <div className="col-12">
                                <small>{fileSizeMessage}</small>
                            </div>
                        }
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={data?.desc}
                                onChange={handleDesc}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 220,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={data?.desc_eng}
                                onChange={handleDescEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 220,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Añadir nuevo producto</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>

                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditGallery
