import React, {useState, createContext, useCallback, useContext} from 'react'
import {logOut} from '../services/ApiClient'

const AuthContext = createContext()

export const useAuthContext = () => useContext(AuthContext)

export const AuthContextProvider = ({children}) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const [language, setLanguage] = useState(JSON.parse(localStorage.getItem('language')) || 'ES')
  const [popup, setPopup] = useState(JSON.parse(localStorage.getItem('popup')) || 'SI')

  const login = useCallback(user => {
    localStorage.setItem('user', JSON.stringify(user))
    setUser(user)
  }, [])

  const logout = useCallback(() => {
    logOut()
    localStorage.removeItem('user')
    setUser(undefined)
  }, [])

  const siteLanguage = useCallback(language => {
    localStorage.setItem('language', JSON.stringify(language))
    setLanguage(language)
  }, [])

  const homePopup = useCallback(popup => {
    localStorage.setItem('popup', JSON.stringify(popup))
    setPopup(popup)
  }, [])

  const value = {user, login, logout, siteLanguage, language, homePopup, popup}

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
