import './BiocontrolledLandingPage.css'
import React, {useState, useEffect} from 'react'
import {Helmet} from 'react-helmet'

import {getSeo} from '../../../../services/ApiClient'

import Loader from '../../../Loader/Loader'
import Video from './Video/Video'
import WhoWeAre from './WhoWeAre/WhoWeAre'
import Marquee from './Marquee/Marquee'
import Modified from './Modified/Modified'
import TechTitle from './TechTitle/TechTitle'
import TechCards from './TechCards/TechCards'
import Clinical from './Clinical/Clinical'
import Footer from './Footer/Footer'

function BiocontrolledLandingPage() {

    const [seoInfo, setSeoInfo] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            const getSeoData = await getSeo()
            const filterSeo = getSeoData.filter(seo => seo.page === 'Biocontrolled')
            setSeoInfo(filterSeo[0])
        }
        fetchData()
        setLoading(!loading)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {loading && <Loader />}
            <Helmet>
                <title>{`Grupo LETI | Biocontrolled info`}</title>
                <meta name="description" content={`${seoInfo?.description}`} />
                <meta name="keywords" content={`${seoInfo?.keywords}`} />
            </Helmet>
            <main className='BiocontrolledLandingPage'>
                <Video />
                <WhoWeAre />
                <Marquee />
                <Modified />
                <TechTitle />
                <TechCards />
                <Clinical />
                <Footer />
            </main>
        </>
    )
}

export default BiocontrolledLandingPage
