import './Banner.css'
import React, {useState, useEffect} from 'react'
import {getBannerBioletisan} from '../../../../../services/ApiClient'
import {useAuthContext} from '../../../../../contexts/AuthContext'


function Banner() {

    const [bannerData, setBannerData] = useState()

    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getBannerData = await getBannerBioletisan()
            setBannerData(getBannerData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container LandingBanner">
            <div className="row LandingBanner--main">
                <div className="col-12 col-sm-4 custom">
                    <h1>{language === 'ES' ? bannerData?.title : bannerData?.title_eng}</h1>
                </div>
                <div className="col-12 col-sm-9 custom--absolute">
                    <div className="flex-wraper">
                        <div className="item">
                            <div className="card-wrapper">
                                <figure>
                                    <img src={bannerData?.imgURLOne} alt="bioletisan" />
                                </figure>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card-wrapper">
                                <figure>
                                    <img src={bannerData?.imgURLTwo} alt="bioletisan" />
                                </figure>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card-wrapper">
                                <figure>
                                    <img src={bannerData?.imgURLThree} alt="bioletisan" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
