import React, {useState, useEffect} from 'react'
import {getSocialBioletisan, updateBannerRrssData} from '../../../../services/ApiClient.js'
import {Helmet} from 'react-helmet'
import {useFormState} from '../../../../hooks/useFormState.js'
import {app} from '../../../../services/firebase.js'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel.jsx'
import InputFile from '../../../Form/InputFile/InputFile.jsx'
import Button from '../../../Form/FormButton/FormButton.jsx'
import {Editor} from '@tinymce/tinymce-react'

function EditBannerRRSS() {

    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [registerError, setRegisterError] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const [socialData, setSocialData] = useState([])

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: socialData?.title,
                title_eng: socialData?.title_eng,
                description: socialData?.description,
                description_eng: socialData?.description_eng,
                background: socialData?.background,
                link: socialData?.link,
            },
            error: {
                title: true,
                title_eng: true,
                description: true,
                description_eng: true,
                background: true,
                link: true,
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
            background: v => v.length,
            link: v => v.length,
        }
    )

    const {data, error} = state

    const updateBannerRrss = async (event) => {
        event.preventDefault()
        data.id = socialData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateBannerRrssData(data)
                    .then(banner => {
                        setSocialData(banner[0])
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const handleDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleTitle = (e) => {
        data.title = e.target.getContent()
        error.title = false
    }

    const handleTitleEng = (e) => {
        data.title_eng = e.target.getContent()
        error.title_eng = false
    }

    const handleTechDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setMessage('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen uno subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.background = fileUrl
            setIsDisabled(false)
            error.background = false
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const getSocialData = await getSocialBioletisan()
            setSocialData(getSocialData[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>
            <section className="container-fluid EditContent AdminBioletisanLandingPage">
                <h2>Editar banner RRSS</h2>

                <form className="AdminEdit__form" onSubmit={updateBannerRrss}>
                    <div className="row">
                        <div className="col-12">
                            <div className="col-12 EditElementsModal__img">
                                <img src={socialData?.background} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={socialData?.title} />
                                <InputFile
                                    value={socialData?.background}
                                    onChange={onFileSelected}
                                    id="fileButton"
                                    name="background"
                                    type="file"
                                    placeholder={socialData?.background}
                                    label="Fondo banner"
                                />
                                {
                                    fileSizeMessage &&
                                    <small>{fileSizeMessage}</small>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Título
                            </p>
                            <Editor
                                initialValue={socialData?.title}
                                onChange={handleTitle}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Title
                            </p>
                            <Editor
                                initialValue={socialData?.title_eng}
                                onChange={handleTitleEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={socialData?.description}
                                onChange={handleDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={socialData?.description_eng}
                                onChange={handleTechDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <InputWithLabel
                                value={data?.link}
                                label="Perfil instagram"
                                onChange={onChange}
                                name="link"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={socialData?.link}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                        {message && <span className="AdminEdit__message">{message}</span>}
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditBannerRRSS

