import './FindProduct.css'
import React, {useState, useEffect} from 'react'
import Loader from '../../../Loader/Loader'

import DropdownWithLabel from '../../../Form/DropdownWithLabel/DropdownWithLabel'
import {getVadevecumData, getSuppliers} from '../../../../services/ApiClient'
import {useAuthContext} from '../../../../contexts/AuthContext'
import {useFormState} from '../../../../hooks/useFormState'
import {Slide} from 'react-awesome-reveal'
import {useHistory} from 'react-router'
import {seoURLPA} from "../../../../hooks/seoURLPA";

function FindProduct({info}) {

    const [vadevecum, setVadevecum] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    const {state, onChange} = useFormState(
        {
            data: {
                search: "",
                especialidad: "",
                active_principle: ""
            },
            error: {
                search: true,
                especialidad: true,
                active_principle: true
            },
            touch: {},
        },
        {
            search: v => v.length,
            especialidad: v => v.length,
            active_principle: v => v.length
        }
    )

    const {data, error, touch} = state

    let history = useHistory()

    const searchSubmit = (event) => {
        event.preventDefault()

        const producto = data?.search

        const objetoEncontrado = vadevecum.find(objeto => 
            producto.toLowerCase().startsWith(objeto.name.toLowerCase())
          );
        const active = objetoEncontrado?.active_principle;

        if (objetoEncontrado) {
            let pathname = "";
            if(objetoEncontrado?.subLine === "Dolor/SNC" || objetoEncontrado?.subLine === "Cardiometabólica") {
                pathname = `${seoURLPA(objetoEncontrado?.name)}-`
            }
            history.push({
                pathname: `/producto-${pathname}${seoURLPA(active)}${objetoEncontrado?.line === "Genven" ? "-genven" : ""}`,
                state: {
                    buscar: data.search,
                    especialidad: data.especialidad
                }
            })
        } else {
            history.push({
                pathname: `/no-encontrado`
            })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getData = await getVadevecumData()
            const getSuppliersData = await getSuppliers()
            const showAllowedProducts = getData.filter(el => el.show_in_products === true)
            setVadevecum(showAllowedProducts)
            setSuppliers(getSuppliersData)
        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {loading && <Loader />}
            <Slide direction="up" triggerOnce>
                <section className="container-fluid FindProduct no-back">
                    <div className="container">
                        <div className="row FindProduct__row">
                            <div className="col-12">
                                <h1 className="FindProduct__custom__title" dangerouslySetInnerHTML={{__html: language === 'ES' ? info?.findProductsTitle : info?.findProductsTitle_eng}} />
                                <form className="FindProduct__form">
                                    <div className="input-group">
                                        <div className="col-12 p-0 col-sm-11 FindProduct__label">
                                            <DropdownWithLabel
                                                placeholder={language === 'ES' ? "Buscar por producto" : "Search by product"}
                                                value={data?.search}
                                                label=""
                                                name="search"
                                                onChange={onChange}
                                                cssStyle={`product form-control ${touch?.search && error?.search ? "is-invalid" : ""}`}
                                                list="searchs"
                                                data={[...new Set(vadevecum?.map(v => v.name))].sort()}
                                            />
                                        </div>
                                        {/* <div className="col-12 p-0 col-sm-4">
                                            <DropdownWithLabel
                                                placeholder={language === 'ES' ? "Especialidad médica" : "Medical speciality"}
                                                value={data?.especialidad}
                                                label=""
                                                name="especialidad"
                                                onChange={onChange}
                                                cssStyle={`category form-control ${touch?.especialidad && error?.especialidad ? "is-invalid" : ""}`}
                                                list="especialidades"
                                                data={language === 'ES' ? dataSpecialities : dataSpecialitiesEng}
                                            />
                                        </div> */}
                                        <div onClick={searchSubmit} className="col-12 p-0 col-sm-1 leti-btn">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row FindProduct__row FindProduct__row--custom">
                            <div className="col-12">
                                <h1 dangerouslySetInnerHTML={{__html: language === 'ES' ? suppliers[0]?.title : suppliers[0]?.title_eng}} />
                            </div>
                            <div className='row justify-content-around'>
                                {suppliers[0]?.imgURLFarmatodo &&
                                    <div className='col-12 col-sm-3 logo farmatodo'>
                                        <a href={suppliers[0]?.URLFarmatodo} target="_blank" rel="noreferrer" >
                                            <img src={suppliers[0]?.imgURLFarmatodo} alt='logo' />
                                        </a>
                                    </div>
                                }
                                {suppliers[0]?.imgURLLocatel &&
                                <div className='col-12 col-sm-3 logo locatel'>
                                    <a href={suppliers[0]?.URLLocatel} target="_blank" rel="noreferrer" >
                                        <img src={suppliers[0]?.imgURLLocatel} alt='logo' />
                                    </a>
                                </div>
                                }
                                {suppliers[0]?.imgURLFarmadon &&
                                <div className='col-12 col-sm-3 logo farmadon'>
                                    <a href={suppliers[0]?.URLFarmadon} target="_blank" rel="noreferrer" >
                                        <img src={suppliers[0]?.imgURLFarmadon} alt='logo' />
                                    </a>
                                </div>
                                }
                                {suppliers[0]?.imgURLFarmabien &&
                                <div className='col-12 col-sm-3 logo farmabien'>
                                    <a href={suppliers[0]?.URLFarmabien} target="_blank" rel="noreferrer" >
                                        <img src={suppliers[0]?.imgURLFarmabien} alt='logo' />
                                    </a>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </Slide>
        </>
    )
}

export default FindProduct
