import React, {useState, useEffect} from 'react'
import './EditProductLines.css'
import {useFormState} from '../../../../../hooks/useFormState'
import {getLines, deleteLine, createLine} from '../../../../../services/ApiClient'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'
import Button from '../../../../Form/FormButton/FormButton'

function EditProductLines() {

    const [linesData, setLinesData] = useState([])

    const [message, setMessage] = useState('')
    const [messageCreate, setMessageCreate] = useState('')

    const {state, onBlur, onChange} = useFormState(
        {
            data: {
                line: '',
                line_eng: '',
            },
            error: {
                line: true,
                line_eng: true,
            },
            touch: {},
        },
        {
            line: v => v.length,
            line_eng: v => v.length,
        }
    )

    const {data, error, touch} = state
    const [registerError, setRegisterError] = useState(null)

    const createNewLine = async (e) => {
        e.preventDefault()

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await createLine(data)
                    .then(lines => {
                        setLinesData(lines)
                        setMessageCreate('Data atualizada exitosamente')
                        data.line = ''
                        data.line_eng = ''
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessageCreate('Por favor complete ambos campos')
        }
    }


    const deleteSelectedLine = async (id) => {
        const result = await deleteLine(id)
        setLinesData(result)
        setMessage('Línea borrada correctamente.')
    }

    useEffect(() => {
        const fetchData = async () => {
            const getLinesData = await getLines()
            setLinesData(getLinesData)
            setMessage('')
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container-fluid EditContent EditProductLines">
                <h2>Crear nuevas líneas</h2>
                <form className="AdminEdit__form" onSubmit={createNewLine}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Nueva línea
                            </p>
                            <InputWithLabel
                                value={data?.line}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="line"
                                type="text"
                                cssStyle={`form-control mb-3 ${touch.line && error.line ? "is-invalid" : ""}`}
                                placeholder="Ingresa nueva etiqueta"
                            />
                            <p className="AdminEdit__form__label en">
                                New line
                            </p>
                            <InputWithLabel
                                value={data?.line_eng}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="line_eng"
                                type="text"
                                cssStyle={`form-control mb-0 ${touch.line_eng && error.line_eng ? "is-invalid" : ""}`}
                                placeholder="Ingresa nueva etiqueta"
                            />
                        </div>
                        <div className="col-sm-6 col-12">
                            <Button cssStyle="leti-btn hidden-label AdminEdit__form-leti-btn" >Añadir nueva línea</Button>

                            {messageCreate &&
                                <div className="row">
                                    <span className="AdminEdit__message col-12 m-0">{messageCreate}</span>
                                </div>
                            }
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
            <section className="container-fluid EditContent EditProductLines-delete">
                <h2>Eliminar líneas</h2>
                <div className="row">
                    {linesData?.map(el =>
                        <div onClick={() => deleteSelectedLine(el?.id)} className="col-sm-2 col-6 EditProductLines-delete-tags">{el?.line}</div>
                    )}
                    <div className="col-12">
                        {message && <span className="AdminEdit__message m-0">{message}</span>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditProductLines