import './Info.css'
import React, {useState, useEffect} from 'react'
import {getInfoBioletisan} from '../../../../../services/ApiClient'
import {Fade} from 'react-awesome-reveal'

import {useAuthContext} from '../../../../../contexts/AuthContext'


function Info() {

    const [bannerData, setBannerData] = useState([])

    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getBannerData = await getInfoBioletisan()
            setBannerData(getBannerData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container-fluid LandingInfoContent" style={{background: `url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fbannertotal.svg?alt=media&token=a333f355-6c81-4c70-b7fb-b37b92ffd3d8) no-repeat top left / cover`}} id="beneficios">
            <div className="container">
                <div className="row">
                    <Fade direction="down" triggerOnce>
                        <div className='col-12 LandingInfoContent--wrapper'>
                            <h2 dangerouslySetInnerHTML={{__html: language === 'ES' ? bannerData[0]?.subtitle : bannerData[0]?.subtitle_eng}} />
                            <h1 dangerouslySetInnerHTML={{__html: language === 'ES' ? bannerData[0]?.title : bannerData[0]?.title_eng}} />
                            <div className="row LandingInfoContent--cards">
                                {bannerData?.map(el =>
                                    <div className='col-sm-3 col-12 LandingInfoContent--card'>
                                        <span className='icon' style={{background: `url(${el?.icon_url}) no-repeat center / contain`}} />
                                        <b dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.item_tag : el?.item_tag_eng}} />
                                        <span dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.item_description : el?.item_description_eng}} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}

export default Info
