import './BottomCta.css'
import React, {useState, useEffect} from 'react'
import {Fade} from 'react-awesome-reveal'
import {Link} from 'react-router-dom'
import {getBottomManufactureData} from '../../../../services/ApiClient'
import Loader from '../../../Loader/Loader'
import {useAuthContext} from '../../../../contexts/AuthContext'

function BottomCta() {

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getBottomManufacture = await getBottomManufactureData()
            setData(getBottomManufacture)

        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {loading && <Loader />}
            <section className="container-fluid BottomCta">
                <div className="row">
                    {data?.map((el, key) =>
                        <div className={key > 0 ? (language === 'EN' ? "d-none" : "col-12 col-sm-6 p-0 BottomCta__img") : (language === 'EN' ? "col-12 p-0 BottomCta__img" : "col-12 p-0 col-sm-6 BottomCta__img")}>
                            {key === 0 ? <div className="BottomCta__terapias" style={{
                                background: `url("${el?.img}") no-repeat center center / cover`
                            }} /> :
                                <div className="BottomCta__medicamento" style={{
                                    background: `url("${el?.img}") no-repeat center center / cover`
                                }} />}
                            <div className="BottomCta__title">
                                <Fade {...key > 1 && `delay=${300}`} direction="up" triggerOnce>
                                    <h2>{language === 'ES' ? el?.title : el?.title_eng}</h2>
                                    {el?.url?.includes('http') ?
                                        <a href={language === 'ES' ? el?.buttonLink : el?.buttonLink_eng} target="_blank" className="leti-btn" rel="noopener noreferrer">{language === 'ES' ? el?.buttonTitle : el?.buttonTitle_eng}</a>
                                        :
                                        <Link to={language === 'ES' ? el?.buttonLink : el?.buttonLink_eng} className="leti-btn">{language === 'ES' ? el?.buttonTitle : el?.buttonTitle_eng}</Link>
                                    }
                                </Fade>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default BottomCta
