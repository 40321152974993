import './InfoFooter.css'
import React, {useEffect} from 'react'


function InfoFooter() {

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container-fluid LandingInfoFooter d-block d-sm-none" style={{background: `url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Finfo-bottom-bg.svg?alt=media&token=a333f355-6c81-4c70-b7fb-b37b92ffd3d8) no-repeat bottom left / cover`}}>
        </section>
    )
}

export default InfoFooter
