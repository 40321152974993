import './NotFoundPage.css'
import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {useAuthContext} from '../../../contexts/AuthContext'

function NotFoundPage() {
    const {language} = useAuthContext()
    return (
        <>
            <Helmet>
                <title>Grupo LETI | 404</title>
            </Helmet>
            <main className="container NotFoundPage">
                <div className="row justify-content-center">
                    <div className="col-12 NotFoundPage__icon"></div>
                    <div className="col-11 col-sm-4 text-center">
                        {language === 'ES' ?
                            <>
                                <p>Lo sentimos, no encontramos la página que estás buscando.</p>
                                <Link to="/" className="leti-btn">Visita nuestra página de inicio</Link>
                            </>
                            :
                            <>
                                <p>Sorry, we couldn't find the page you're looking for.</p>
                                <Link to="/" className="leti-btn">Visit our home page</Link>
                            </>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default NotFoundPage
