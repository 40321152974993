import React from 'react'
import EditProductLines from './EditProductLines/EditProductLines'

function EditLines() {
    return (

        <main>
            <h2 className="EditContent EditContent__title">Líneas de productos</h2>
            <EditProductLines />
        </main>
    )
}

export default EditLines
