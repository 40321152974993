const dataSpecialities = [
    'ANESTESIOLOGÍA',
    'CARDIOLOGÍA',
    'CIRUGÍA GENERAL',
    'CIRUGÍA PLÁSTICA',
    'COLOPROCTOLOGÍA',
    'DERMATOLOGÍA',
    'ENDOCRINOLOGÍA',
    'FERTILIDAD DE LA MUJER',
    'FERTILIDAD DEL HOMBRE',
    'FISIATRÍA',
    'FONIATRÍA',
    'GASTROENTEROLOGÍA',
    'GINECOLOGÍA',
    'GINECO-OBSTETRICIA',
    'HEMATOLOGÍA',
    'INFECTOLOGÍA',
    'MEDICINA CRÍTICA',
    'MASTOLOGÍA',
    'MEDICINA INTERNA',
    'MEDICINA GENERAL',
    'NEFROLOGÍA',
    'NEUMONOLOGÍA',
    'NEUROCIRUGÍA',
    'NEUROLOGÍA',
    'NEUROLOGÍA INFANTIL',
    'NUTRICIÓN',
    'OFTALMOLOGÍA',
    'ODONTOLOGÍA',
    'ONCOLOGÍA',
    'OTORRINOLARINGOLOGÍA',
    'PSICOLOGÍA',
    'PSIQUIATRÍA',
    'RADIOLOGÍA E IMÁGENES',
    'PEDIATRÍA',
    'REUMATOLOGÍA',
    'SEXOLOGÍA',
    'TRAUMATOLOGÍA',
    'UROLOGÍA',
    'UROLOGÍA ONCOLÓGICA'
]

export default dataSpecialities