import './Header.css'
import React from 'react'
import {Fade} from 'react-awesome-reveal'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'
import {useAuthContext} from '../../../../../contexts/AuthContext'

function Header() {

    const {language} = useAuthContext()

    return (
        <section className="container LandingHeader">
            <div className="flex-container LandingHeader--wrapper">
                <a href='#beneficios' className="left-element">{language === 'ES' ? 'BENEFICIOS' : 'BENEFITS'}</a>
                {language === 'ES' && <a href='#comprar' className="left-element">COMPRAR</a>}
                <div className="center-element">
                    <Fade duration={600} triggerOnce>
                        <Link to={{
                            pathname: `/`
                        }}>
                            <img src="https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Flogo-bioletisan.svg?alt=media&token=23b0f5ff-d1dc-4e69-beda-e46a92ad1c10" onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt="Bioletisan logo" />
                        </Link>

                    </Fade>
                </div>
                <a href='#faq' className="right-element">FAQ</a>
                <a href='#contactanos' className="right-element">{language === 'ES' ? 'CONTÁCTANOS' : 'CONTACT US'}</a>
            </div>
        </section>
    )
}

export default Header
