import './FindProduct.css'
import React, {useEffect, useState} from 'react'
import {useFormState} from '../../../../hooks/useFormState'
import DropdownWithLabel from '../../../Form/DropdownWithLabel/DropdownWithLabel'
import dataSpecialities from '../../../../data/dataSpecialities'
import {Slide} from 'react-awesome-reveal'
import {getVadevecumData} from '../../../../services/ApiClient'
import {useHistory} from 'react-router'
import {useAuthContext} from '../../../../contexts/AuthContext'
import {seoURLPA} from "../../../../hooks/seoURLPA";

function FindProduct() {

    const [vadevecum, setVadevecum] = useState([])
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    const {state, onChange} = useFormState(
        {
            data: {
                search: "",
                especialidad: ""
            },
            error: {
                search: true,
                especialidad: true
            },
            touch: {},
        },
        {
            search: v => v.length,
            especialidad: v => v.length
        }
    )

    const {data, error, touch} = state

    let history = useHistory()

    const searchSubmit = (event) => {
        event.preventDefault()

        const producto = data?.search

        const objetoEncontrado = vadevecum.find(objeto => 
            producto.toLowerCase().startsWith(objeto.name.toLowerCase())
          );
        const active = objetoEncontrado?.active_principle;

        if (objetoEncontrado) {
            let pathname = "";
            if(objetoEncontrado?.subLine === "Dolor/SNC" || objetoEncontrado?.subLine === "Cardiometabólica") {
                pathname = `${seoURLPA(objetoEncontrado?.name)}-`
            }
            history.push({
                pathname: `/producto-${pathname}${seoURLPA(active)}${objetoEncontrado?.line === "Genven" ? "-genven" : ""}`,
                state: {
                    buscar: data.search,
                    especialidad: data.especialidad
                }
            })
        } else {
            history.push({
                pathname: `/no-encontrado`
            })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getData = await getVadevecumData()
            setVadevecum(getData)
        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Slide direction="up" triggerOnce>
            <section className="container-fluid FindProductList">
                <div className="container">
                    <div className="row FindProductList__row">
                        <div className="col-12 col-sm-12">
                            <form className="FindProductList__form">
                                <div className="input-group">
                                    <div className="col-12 p-0 col-sm-7 FindProductList__label">
                                        <DropdownWithLabel
                                            placeholder={language === 'ES' ? "Escribe nombre o condición" : "Type name or condition"}
                                            value={data.search}
                                            label=""
                                            name="search"
                                            onChange={onChange}
                                            cssStyle={`product form-control ${touch.search && error.search ? "is-invalid" : ""}`}
                                            list="searchs"
                                            data={[...new Set(vadevecum.map(v => v.name))].sort()}
                                        />
                                    </div>
                                    <div className="col-12 p-0 col-sm-4">
                                        <DropdownWithLabel
                                            placeholder={language === 'ES' ? "Especialidad médica" : "Medical speciality"}
                                            value={data.especialidad}
                                            label=""
                                            name="especialidad"
                                            onChange={onChange}
                                            cssStyle={`category form-control ${touch.especialidad && error.especialidad ? "is-invalid" : ""}`}
                                            list="especialidades"
                                            data={dataSpecialities}
                                        />
                                    </div>
                                    <div onClick={searchSubmit} className="col-12 p-0 col-sm-1 leti-btn">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>

        </Slide>
    )
}

export default FindProduct
