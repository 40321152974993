import React, {useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {deleteGoalItem, updateGoalData} from '../../../../../services/ApiClient'
import {useFormState} from '../../../../../hooks/useFormState'
import './DeleteItemModal.css'
import InputFile from '../../../../Form/InputFile/InputFile'
import {app} from '../../../../../services/firebase'

function DeleteItemModal({deleteItem, element, hideModal}) {

    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [fileSizeMessage, setFileSizeMessage] = useState('')

    const {state} = useFormState(
        {
            data: {
                id: element.id,
                title: element.title,
                title_eng: element.title_eng,
                icon_url: element.icon_url,
                description: element.description,
                description_eng: element?.description_eng,
            },
            error: {
                icon_url: true,
                description: true,
                description_eng: true,
            },
            touch: {},
        },
        {
            icon_url: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
        }
    )

    const {data, error} = state

    const deleteCarrouselItem = async (id) => {
        const updateData = await deleteGoalItem(id)
        deleteItem(updateData)
    }

    const editGoalItem = async (id) => {

        if (error.description === false || error.icon_url === false || error.description_eng === false) {
            data.id = id
            try {
                await updateGoalData(data, id)
                    .then(updateData => {
                        deleteItem(updateData)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }

        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const handleBannerDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleBannerDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    //Se habilita el botón para subir el blog
                    setDisabled(!disabled)
                })
                .catch(err => {
                    console.log(err)
                    setFileSizeMessage(err)
                })


            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.icon_url = fileUrl
            setIsDisabled(false)
            error.icon_url = false
            setFileSizeMessage("Imagen cargada exitosamente")
        }
    }

    return (
        <div className="EditElementsModal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-8 EditElementsModal__container">
                        <span className="EditElementsModal__close" onClick={hideModal}></span>
                        <div className="col-sm-12">
                            <h1 className="DeleteItemModal__ask">Editar característica</h1>
                            <div className="card">
                                <div className="card-body EditElementsModal__body">
                                    <div className="row align-items-center">
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <p className="AdminEdit__form__label">
                                                        Editar icono
                                                    </p>
                                                    <img src={element?.icon_url} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={element?.title} />
                                                    <InputFile
                                                        value={element?.icon_url}
                                                        onChange={onFileSelected}
                                                        id="fileButton"
                                                        name="icon_url"
                                                        type="file"
                                                        placeholder={element?.icon_url}
                                                    />
                                                </div>

                                                {fileSizeMessage &&
                                                    <div className='col-12'>
                                                        <span className="AdminEdit__message m-0">{fileSizeMessage}</span>
                                                    </div>
                                                }
                                                <div className="col-12 col-sm-6">
                                                    <p className="AdminEdit__form__label">
                                                        Editar descripción
                                                    </p>
                                                    <Editor
                                                        initialValue={data?.description}
                                                        onChange={handleBannerDescription}
                                                        apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                        init={{
                                                            height: 200,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist autolink lists link image',
                                                                'charmap print preview anchor help',
                                                                'searchreplace visualblocks code',
                                                                'insertdatetime media table paste wordcount'
                                                            ],
                                                            toolbar:
                                                                'bold',
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <p className="AdminEdit__form__label en">
                                                        Edit description
                                                    </p>
                                                    <Editor
                                                        initialValue={data?.description_eng}
                                                        onChange={handleBannerDescriptionEng}
                                                        apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                        init={{
                                                            height: 200,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist autolink lists link image',
                                                                'charmap print preview anchor help',
                                                                'searchreplace visualblocks code',
                                                                'insertdatetime media table paste wordcount'
                                                            ],
                                                            toolbar:
                                                                'bold',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div onClick={() => editGoalItem(element._id)} className="leti-btn mt-0">Editar elemento</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div onClick={() => deleteCarrouselItem(element._id)} className="leti-btn mt-0 delete">Eliminar elemento</div>
                                        </div>
                                        <div className="col-12">
                                            {registerError && <div className="alert alert-danger">{registerError}</div>}
                                            {message && <span className="AdminEdit__message m-0">{message}</span>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DeleteItemModal
