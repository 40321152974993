import './EditFAQItems.css'
import React, {useState, useEffect} from 'react'
import {getFaqItems, addFaqItem} from '../../../../services/ApiClient.js'
import {Helmet} from 'react-helmet'
import {Editor} from '@tinymce/tinymce-react'
import EditInfoDataModal from './EditInfoDataModal/EditInfoDataModal.jsx'
import {useFormState} from '../../../../hooks/useFormState.js'
import Button from '../../../Form/FormButton/FormButton.jsx'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel.jsx'

function EditFAQItems() {

    const [modalData, setModalData] = useState([])
    const [bool, setBool] = useState(false)
    const [itemData, setItemData] = useState([])
    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                description: '',
                description_eng: '',
                title: '',
                title_eng: '',
            },
            error: {
                description: true,
                description_eng: true,
                title: true,
                title_eng: true,
            },
            touch: {},
        },
        {
            description: v => v.length,
            description_eng: v => v.length,
            title: v => v.length,
            title_eng: v => v.length,
        }
    )

    const {data, error} = state

    const showModal = (data) => {
        setModalData(data)
        setBool(!bool)
    }

    const deleteItem = (data) => {
        setItemData(data)
        setBool(!bool)
    }

    const createFaqItem = async (event) => {
        event.preventDefault()

        if (error.description === false && error.description_eng === false && error.title === false && error.title_eng === false) {
            try {
                await addFaqItem(data)
                    .then(faq => {
                        setItemData(faq)
                        setMessage('Pregunta creada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor rellene todos los campos')
        }
    }

    const handleInfoDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleInfoDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    useEffect(() => {
        const fetchData = async () => {
            const getFaqItemsData = await getFaqItems()
            setItemData(getFaqItemsData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>
            {bool && <EditInfoDataModal hideModal={() => setBool(!bool)} element={modalData} deleteItem={(updateData) => deleteItem(updateData)} />}
            <section className="container-fluid Letilabs EditContent EditContent-timeline EditFAQItems">
                <h2>Editar preguntas FAQ</h2>
                <div className="row justify-content-around">
                    {itemData?.map(el =>
                        <div className="col-12 EditCarousel__edit EditCarousel__edit-force EditFAQItems-faqs" onClick={() => showModal(el)}>
                            {el?.title && <h5>{el?.title}</h5>}
                        </div>
                    )}
                </div>
                <hr className="mt-5 mb-5" />
                <form className="AdminEdit__form" onSubmit={createFaqItem}>
                    <div className="row">
                        <h3>Añadir nueva pregunta</h3>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title}
                                label="Título"
                                onChange={onChange}
                                name="title"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder=''
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title_eng}
                                label="Title"
                                onChange={onChange}
                                name="title_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder=''
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={data?.description}
                                onChange={handleInfoDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={data?.description_eng}
                                onChange={handleInfoDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Añadir nuevo beneficio</Button>
                            {message && <span className="AdminEdit__message ">{message}</span>}
                        </div>

                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditFAQItems

