import './Video.css'
import React from 'react'


function Video() {

    return (
        <section className="container-fluid BiocontrolledInfo-Video">
            <video poster="https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fbiocontrolled-video-poster.jpg?alt=media&token=9253cc45-7b15-4a0b-a177-622e5453755b" loop muted autoPlay playsInline>
                <source src="https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/videos%2Fbiocontrolled-video-header.mp4?alt=media&token=8f7cec8b-9456-41c3-8e4f-b74f6eed0ce6" type="video/mp4" />
            </video>
        </section>
    )
}

export default Video
