import React, {useState, useEffect} from 'react'
import {Fade} from 'react-awesome-reveal'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import Slider from "react-slick"

import {getProduct, getProductDataFromActivePrinciple, getSuppliers} from '../../../services/ApiClient'

import './SingleProductPage.css'
import {useAuthContext} from '../../../contexts/AuthContext'
import {seoURLPA} from "../../../hooks/seoURLPA"

function SingleProductPage(props) {

    let settings = {
        slidesToShow: 1,
        speed: 500,
        dots: true,
        arrows: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const buscar = props?.location?.state?.buscar

    const [product, setProduct] = useState([])
    const [productName, setProductName] = useState('')
    const [supplier, setSupplier] = useState('')
    const {language} = useAuthContext()

    useEffect(() => {
        window.scrollTo(0, 0)
        const fetchData = async () => {
            const pathname = props?.location?.pathname?.slice(1)
            if (buscar !== undefined) {
                const getProductData = await getProduct(buscar)
                setProduct(getProductData)
                setProductName(getProductData[0][0].name)
                const getSupplierData = await getSuppliers()
                setSupplier(getSupplierData)
            } else {
                const getProductData = await getProductDataFromActivePrinciple(pathname)
                setProduct(getProductData)
                setProductName(getProductData[0][0].name)
                const getSupplierData = await getSuppliers()
                setSupplier(getSupplierData)
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buscar])

    return (
        <>
            <Helmet>
                <title>{`Grupo LETI | Producto | ${productName}`}</title>
                <meta name="description" content={`Resultado de búsqueda de producto del Grupo LETI: ${productName}`} />
                <meta name="keywords" content={`Grupo LETI, productos leti, ${productName}`} />
            </Helmet>
            <section className="container SingleProductPage">
                <div className="row">
                    <div className="col-12 SingleProductPage__back">
                        <Link className="SingleProductPage__link" to="/listado-de-productos">{language === 'ES' ? "Regresar al listado de productos" : "Back to product list"}</Link>
                    </div>
                    {product[0]?.length === 0 &&
                        <h2>{language === 'ES' ? "Este producto no existe" : "This product does not exist"}</h2>}
                    {product.length > 1 ?
                        <Fade triggerOnce cascade direction="up">
                            <Slider {...settings}>
                                {product[0].map(el =>
                                    <>
                                        <div className="col-12 SingleProductPage__product">
                                            <div className="row justify-content-between">
                                                <div className='col-12 col-sm-6'>
                                                    <div className='row justify-content-center'>
                                                        <div className="col-12 SingleProductPage__pic" style={{
                                                            background: `url("${el?.picPath ? el?.picPath : "https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2FImagen%20no%20disponible%20actualmente.jpg?alt=media&token=96007dfd-40f5-4b43-9b3d-8883b4f930bf"}") no-repeat center center / contain`
                                                        }} />
                                                        <div className='row SingleProductPage__product--links'>
                                                            {el?.supplierLocatelLink &&
                                                                <div className='col-12 col-sm-4 SingleProductPage__pic--logo locatel'>
                                                                    <a href={el?.supplierLocatelLink} target="_blank" rel="noreferrer">
                                                                        <img src={supplier[0]?.imgURLLocatel} alt='logo' />
                                                                    </a>
                                                                </div>
                                                            }
                                                            {el?.supplierFarmatodoLink &&
                                                                <div className='col-12 col-sm-4 SingleProductPage__pic--logo farmatodo'>
                                                                    <a href={el?.supplierFarmatodoLink} target="_blank" rel="noreferrer">
                                                                        <img src={supplier[0]?.imgURLFarmatodo} alt='logo' />
                                                                    </a>
                                                                </div>
                                                            }
                                                            {el?.supplierFarmadonLink &&
                                                                <div className='col-12 col-sm-4 SingleProductPage__pic--logo farmadon'>
                                                                    <a href={el?.supplierFarmadonLink} target="_blank" rel="noreferrer">
                                                                        <img src={supplier[0]?.imgURLFarmadon} alt='logo' />
                                                                    </a>
                                                                </div>
                                                            }
                                                            {el?.supplierFarmabienLink &&
                                                                <div className='col-12 col-sm-4 SingleProductPage__pic--logo farmabien'>
                                                                    <a href={el?.supplierFarmabienLink} target="_blank" rel="noreferrer">
                                                                        <img src={supplier[0]?.imgURLFarmabien} alt='logo' />
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 SingleProductPage__info">
                                                    <h1>{el?.name}</h1>
                                                    <h2 dangerouslySetInnerHTML={{__html: el?.active_principle}} />
                                                    <div className="SingleProductPage__info__tag--wrapper">
                                                        {el?.therapeutic_group.map(tag => <span className={el?.therapeutic_group?.length > 1 ? 'tag' : 'tag tag__only'}>{tag}</span>)}
                                                    </div>
                                                    <p><strong>{language === 'ES' ? "Composición" : "Composition"}</strong> <span dangerouslySetInnerHTML={{__html: el?.composition}} /></p>
                                                    <p><strong>{language === 'ES' ? "Registro sanitario" : "Health Register"}</strong> <span dangerouslySetInnerHTML={{__html: el?.health_register}} /></p>
                                                    {/* <p><strong>Indicación</strong> <span dangerouslySetInnerHTML={{__html: el?.indication}} />
                                                    </p> */}
                                                    {/* <p><strong>Presentación</strong> <span dangerouslySetInnerHTML={{__html: el?.presentation}} /></p> */}
                                                    {/* <p><strong>Vida útil</strong> <span dangerouslySetInnerHTML={{__html: el?.util_life}} /></p> */}
                                                    {/* <p><strong>CPE</strong> <span className="SingleProductPage__cpe">{el?.cpe}</span></p> */}
                                                    {/* {
                                                        el?.QRpath &&
                                                        <img src={el?.QRpath} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={el?.name + ' código de barras'} className="SingleProductPage__cb" />
                                                    } */}
                                                    {language === 'ES' &&
                                                        <>
                                                            <Link to={{
                                                                pathname: `/${el?.line === "Ética" || el?.line === "Genven" ? "prospecto" : "ficha-tecnica"}-${seoURLPA(el?.name)}`,
                                                                state: {
                                                                    data: el
                                                                }
                                                            }} className="leti-btn SingleProductPage__another-btn">{el?.line === "Ética" || el?.line === "Genven" ? "Ver prospecto" : "Ver ficha técnica"}</Link>

                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-sm-3 col-12 SingleProductPage__posology">
                                                <p>
                                                    <h2>{language === 'ES' ? "Indicación" : "Health Indication"}</h2>
                                                    <ul>
                                                        <li dangerouslySetInnerHTML={{__html: el?.indication}}>
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <div className="col-sm-3 col-12 SingleProductPage__posology">
                                                <p>
                                                    <h2>{language === 'ES' ? "Presentación" : "Presentation"}</h2>
                                                    <ul>
                                                        <li dangerouslySetInnerHTML={{__html: el?.presentation}}>
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <div className="col-sm-3 col-12 SingleProductPage__posology">
                                                <p>
                                                    <h2>{language === 'ES' ? "Posología" : "Posology"}</h2>
                                                    <ul>
                                                        <li dangerouslySetInnerHTML={{__html: el?.posology}}>
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Slider>
                        </Fade>
                        :
                        <>
                            <div className="col-12 SingleProductPage__product">
                                <div className="row justify-content-around">
                                    <div className='col-12 col-sm-6'>
                                        <div className="SingleProductPage__pic" style={{
                                            background: `url(${product[0]?.picPath}) no-repeat center / contain`,
                                        }} />
                                        {product[0]?.supplierLocatelLink &&
                                            <div className='col-12 col-sm-4 SingleProductPage__pic--logo'>
                                                <a href={product[0]?.supplierLocatelLink} target="_blank" rel="noreferrer">
                                                    <img src={supplier[0]?.imgURLLocatel} alt='logo' />
                                                </a>
                                            </div>
                                        }
                                        {product[0]?.supplierFarmatodoLink &&
                                            <div className='col-12 col-sm-4 SingleProductPage__pic--logo'>
                                                <a href={product[0]?.supplierFarmatodoLink} target="_blank" rel="noreferrer">
                                                    <img src={supplier[0]?.imgURLFarmatodo} alt='logo' />
                                                </a>
                                            </div>
                                        }
                                    </div>

                                    <div className="col-12 col-sm-6 SingleProductPage__info">
                                        <>
                                            <h1>{product[0]?.name}</h1>
                                            <h2>{product[0]?.active_principle}</h2>
                                            {product[0]?.therapeutic_group?.length > 1 ? product[0]?.therapeutic_group.map(el => <span>{el}</span>) : <span>{product[0]?.therapeutic_group}</span>}
                                            <p><strong>{language === 'ES' ? "Composición" : "Composition"}</strong> {product[0]?.composition}</p>
                                            <p><strong>Indicación</strong>
                                                <span dangerouslySetInnerHTML={{__html: product[0]?.indication}}>
                                                </span>
                                            </p>
                                            <p><strong>{language === 'ES' ? "Presentación" : "Presentation"}</strong> {product[0]?.presentation}</p>
                                            <p><strong>{language === 'ES' ? "Registro sanitario" : "Health Register"}</strong> {product[0]?.health_register}</p>
                                            <p><strong>{language === 'ES' ? "Vida útil" : "Util life"}</strong> {product[0]?.util_life}</p>
                                            <p className="SingleProductPage__cpe">{product[0]?.CPE}</p>
                                            {/* <img src={product[0]?.QRpath} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={product[0]?.name + ' código de barras'} className="SingleProductPage__cb" /> */}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-sm-3 col-12 SingleProductPage__posology">
                                    <p>
                                        <h2>{language === 'ES' ? "Reacciones adversas" : "Adverse reactions"}</h2>
                                        <ul>
                                            <li dangerouslySetInnerHTML={{__html: product[0]?.adverse_reactions}}>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className="col-sm-3 col-12 SingleProductPage__posology">
                                    <p>
                                        <h2>{language === 'ES' ? "Modo de empleo" : "How to use"}</h2>
                                        <ul>
                                            <li dangerouslySetInnerHTML={{__html: product[0]?.how_to_use}}>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className="col-sm-3 col-12 SingleProductPage__posology">
                                    <p>
                                        <h2>{language === 'ES' ? "Contraindicaciones" : "Contraindications"}</h2>
                                        <ul>
                                            <li dangerouslySetInnerHTML={{__html: product[0]?.contraindications}}>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 SingleProductPage__posology">
                                <p>
                                    <h2>{language === 'ES' ? "Posología" : "Posology"}</h2>
                                    <ul>
                                        <li dangerouslySetInnerHTML={{__html: product[0]?.posology}}>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </>
                    }

                    <div className="col-12 SingleProductPage__another">
                        <h1>Otros productos del portafolio</h1>
                        <div className="row justify-content-between">
                            {product[1]?.map(el => {
                                let pathname = "";
                                if(el?.subLine === "Dolor/SNC" || el?.subLine === "Cardiometabólica") {
                                    pathname = `${seoURLPA(el?.name)}-`
                                }
                                return <div className="col-12 col-sm-3 SingleProductPage__another__block">
                                        <h2 className="SingleProductPage__another__img SingleProductPage__another__img-txt">{el?.name}</h2>
                                        <h2>{el?.name}</h2>
                                        <p dangerouslySetInnerHTML={{__html: el?.active_principle}}/>
                                        <Link to={{
                                            pathname: `/producto-${pathname}${seoURLPA(el?.active_principle)}${el?.line === "Genven" ? "-genven" : ""}`,
                                            state: {
                                                buscar: el?.name
                                            }
                                        }} className="leti-btn">{language === 'ES' ? "Ver producto" : "See product"}</Link>
                                    </div>;
                                }
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SingleProductPage
