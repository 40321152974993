import './News.css'
import React, {useState, useEffect} from 'react'
import {getNewsBioletisan, getNewsItems, getNews} from '../../../../../services/ApiClient'

import {useAuthContext} from '../../../../../contexts/AuthContext'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'


function News() {

    const [setNews, setNewsData] = useState([])
    const [newsItems, setNewsItems] = useState([])
    const [bioletisanNews, setBioletisanNews] = useState([])

    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getNewsData = await getNewsBioletisan()
            setNewsData(getNewsData[0])
            const getNewsItemsData = await getNewsItems()
            setNewsItems(getNewsItemsData)
            const getAllNews = await getNews()
            setBioletisanNews(getAllNews.filter(item => item.tag.includes("Bioletisan")))
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {language === 'ES' && bioletisanNews?.length > 0 &&
                <section className="container LandingNews">
                    <div className="row LandingNews--main">
                        <div className='col-12 col-sm-3 LandingNews--info'>
                            <h2 dangerouslySetInnerHTML={{__html: language === 'ES' ? setNews?.tag : setNews?.tag_eng}} />
                            <h1 dangerouslySetInnerHTML={{__html: language === 'ES' ? setNews?.title : setNews?.title_eng}} />
                        </div>
                        {bioletisanNews?.map(el =>
                            <>
                                <div className='col-12 col-sm-3 LandingNews--image' style={{background: `url(${el?.urlToPic}) no-repeat center / cover`}}></div>
                                <a href={el?.link_url} className='col-12 col-sm-3 LandingNews--card'>
                                    <div className='LandingNews--card--tag' dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.title : el?.title_eng}} />
                                    <div className='LandingNews--card--content'>
                                        <div className='LandingNews--card--content--text' dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.subTitle : el?.subTitle_eng}} />
                                        <div className='LandingNews--card--content--link'>
                                            VER MÁS
                                        </div>
                                    </div>
                                </a>
                            </>
                        )}
                        <a href={setNews?.link_url} className='col-12 col-sm-3 LandingNews--card LandingNews--card--seeall'>
                            <div className='LandingNews--card--content'>
                                <div className='LandingNews--card--content--text' dangerouslySetInnerHTML={{__html: language === 'ES' ? setNews?.description : setNews?.description_eng}} />
                                <Link to="/noticias" className='LandingNews--card--content--link'>Ver todas</Link>
                            </div>
                        </a>
                    </div>
                </section>
            }
        </>
    )
}

export default News
