import React, {useState, useEffect} from 'react'
import {getTechBioletisan, updateBioletisanTechData} from '../../../../services/ApiClient'
import {Helmet} from 'react-helmet'
import {useFormState} from '../../../../hooks/useFormState.js'
import {app} from '../../../../services/firebase'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel'
import InputFile from '../../../Form/InputFile/InputFile'
import Button from '../../../Form/FormButton/FormButton'
import {Editor} from '@tinymce/tinymce-react'

function EditBuyProduct() {

    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [registerError, setRegisterError] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const [techData, setTechData] = useState([])

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                link: techData?.link,
                link_eng: techData?.link_eng,
                link_url: techData?.link_url,
                bg_url: techData?.bg_url,
                description: techData?.description,
                description_eng: techData?.description_eng,
            },
            error: {
                link: true,
                link_eng: true,
                link_url: true,
                bg_url: true,
                description: true,
                description_eng: true,
            },
            touch: {},
        },
        {
            link: v => v.length,
            link_eng: v => v.length,
            link_url: v => v.length,
            bg_url: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
        }
    )

    const {data, error} = state

    const handleTechDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleTechDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const updateTech = async (event) => {
        event.preventDefault()
        data.id = techData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateBioletisanTechData(data)
                    .then(info => {
                        setTechData(info)
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setMessage('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setMessage("Imagen uno subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.imgURLOne = fileUrl
            setIsDisabled(false)
            error.imgURLOne = false
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getTechData = await getTechBioletisan()
            setTechData(getTechData[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>
            <section className="container-fluid EditContent AdminBioletisanLandingPage">
                <h2>Editar bloque comprar Bioletisan</h2>
                <form className="AdminEdit__form" onSubmit={updateTech}>
                    <div className="row">
                        <div className="col-12">
                            <div className="col-12 EditElementsModal__img">
                                <img src={techData?.bg_url} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={techData?.title} />
                                <InputFile
                                    value={techData?.bg_url}
                                    onChange={onFileSelected}
                                    id="fileButton"
                                    name="bg_url"
                                    type="file"
                                    placeholder={techData?.bg_url}
                                    label="Imagen dummie"
                                />
                                {
                                    fileSizeMessage &&
                                    <small>{fileSizeMessage}</small>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={techData?.description}
                                onChange={handleTechDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Description
                            </p>
                            <Editor
                                initialValue={techData?.description_eng}
                                onChange={handleTechDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.link}
                                label="Texto botón"
                                onChange={onChange}
                                name="link"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={techData?.link}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.link_eng}
                                label="Button text"
                                onChange={onChange}
                                name="link_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={techData?.link_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.link_url}
                                label="URL botón"
                                onChange={onChange}
                                name="link_url}"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={techData?.link_url}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditBuyProduct

