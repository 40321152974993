import './SwitchWithLabel.css'
import React from 'react'

export default function SwitchWithLabel({checked, onChange}) {

    return (
        <div className="SwitchWithLabel form-group">
            <div class="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    defaultChecked={checked}
                    onChange={onChange} />
                <label class="form-check-label" for="flexSwitchCheckChecked">
                    {checked ? "Publicar producto" : "No publicar producto"}
                </label>
            </div>
            <div className="invalid-feedback">campo obligatorio</div>
        </div>
    )
}