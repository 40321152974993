import React, {useState, useEffect} from 'react'
import {getTechCards, addTechItem} from '../../../../services/ApiClient.js'
import {useFormState} from '../../../../hooks/useFormState.js'
import Button from '../../../Form/FormButton/FormButton.jsx'
import DeleteItemModal from './DeleteItemModal/DeleteItemModal.jsx'

import {Editor} from '@tinymce/tinymce-react'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel.jsx'

function EditCards() {

    const [registerError, setRegisterError] = useState(null)
    const [bool, setBool] = useState(false)
    const [modalData, setModalData] = useState([])
    const [newItemMessage, setNewItemMessage] = useState([])
    const [techCardsData, setTechCardsData] = useState([])

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: '',
                title_eng: '',
                link: '',
                description: '',
                description_eng: ''
            },
            error: {
                title: true,
                description: true,
                title_eng: true,
                description_eng: true,
                link: true
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            link: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
        }
    )

    const {data, error} = state

    const handleDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const deleteItem = (data) => {
        setTechCardsData(data)
        setBool(!bool)
    }

    const showModal = (data) => {
        setModalData(data)
        setBool(!bool)
    }

    const createTechItem = async (event) => {
        event.preventDefault()

        if (error.title === false && error.title_eng === false && error.link === false && error.description === false && error.description_eng === false) {
            try {
                await addTechItem(data)
                    .then(tech => {
                        setTechCardsData(tech)
                        setNewItemMessage('Tecnología creada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setNewItemMessage('Por favor rellene todos los campos')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getTechCardsData = await getTechCards()
            setTechCardsData(getTechCardsData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {bool && <DeleteItemModal hideModal={() => setBool(!bool)} element={modalData} deleteItem={(updateData) => deleteItem(updateData)} />}
            <section className="container-fluid Letilabs EditContent EditContent-timeline">
                <h2>Editar tecnologías</h2>
                <div className="row justify-content-around">
                    {techCardsData?.map(el =>
                        <div className="col-sm-3 col-12 EditCarousel__edit EditCarousel__edit-force" onClick={() => showModal(el)}>
                            <h4>{el?.title}</h4>
                            <p dangerouslySetInnerHTML={{__html: el?.description}} />
                        </div>
                    )}
                </div>
            </section>
            <section className="container-fluid EditContent">
                <h2>Añadir nueva tecnología</h2>
                <form className="AdminEdit__form" onSubmit={createTechItem}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title}
                                label="Título"
                                onChange={onChange}
                                name="title"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder=''
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title_eng}
                                label="Title"
                                onChange={onChange}
                                name="title_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder=''
                                en
                            />
                        </div>
                        <div className="col-12">
                            <InputWithLabel
                                value={data.link}
                                label="Link"
                                onChange={onChange}
                                name="link"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder=''
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={data?.description}
                                onChange={handleDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={data?.description_eng}
                                onChange={handleDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Añadir nuevo elemento</Button>
                            {newItemMessage && <span className="AdminEdit__message ">{newItemMessage}</span>}
                        </div>

                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditCards