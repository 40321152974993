import {useState, useEffect} from 'react'
import './Marquee.css'
import React from 'react'
import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getMarquee} from '../../../../../services/ApiClient'

function Marquee() {

    const [marqueeData, setMarqueeData] = useState([])
    const {language} = useAuthContext()

    const marquee = (selector, speed) => {
        const parentSelector = document.querySelector(selector)
        const clone = parentSelector.innerHTML
        const firstElement = parentSelector.children[0]
        let i = 0
        parentSelector.insertAdjacentHTML('beforeend', clone)
        parentSelector.insertAdjacentHTML('beforeend', clone)

        setInterval(function () {
            firstElement.style.marginLeft = `-${i}px`
            if (i > firstElement.clientWidth) {
                i = 0
            }
            i = i + speed
        }, 0)
    }

    useEffect(() => {
        const fetchData = async () => {
            const getMarqueeData = await getMarquee()
            setMarqueeData(getMarqueeData)
        }
        fetchData()

        //after window is completed load
        //1 class selector for marquee
        //2 marquee speed 0.2
        window.addEventListener('load', marquee('.marquee', 0.2))
    }, [])


    return (
        <>
            <section className="container-fluid BiocontrolledInfo-Marquee">
                <div className='row'>
                    <div className='col-12'>
                        <div className="marquee">
                            <p className="marquee__item">{language === 'ES' ? marqueeData?.text : marqueeData?.text_eng}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Marquee
