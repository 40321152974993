import React, {useState, useEffect} from 'react'
import {getIDInfoBanner} from '../../../../../services/ApiClient'

import EditItemModal from './EditItemModal/EditItemModal'
import './EditInfoBannerIAD.css'

function EditInfoBannerIAD() {
    const [modalData, setModalData] = useState()
    const [ourOCData, setOurOCData] = useState()
    const [wholeData, setWholeData] = useState()
    const [bool, setBool] = useState(false)

    const showModal = (info) => {
        setModalData(info)
        setBool(!bool)
    }

    const hideModal = (info) => {
        setOurOCData(info)
        setBool(!bool)
    }

    useEffect(() => {
        const fetchData = async () => {
            const getOurOCData = await getIDInfoBanner()
            setOurOCData(getOurOCData[0].item)
            setWholeData(getOurOCData[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {bool && <EditItemModal hideModal={(data) => hideModal(data)} infodata={modalData} whole={wholeData} closeModal={() => setBool(!bool)} />}
            {ourOCData?.length > 0 &&
                <section className="container-fluid EditContent EditContent-timeline ">
                    <h2>Editar elemento del banner</h2>
                    <div className="row justify-content-around">
                        {ourOCData?.map(el =>
                            <div className="col-sm-3 col-6 EditCarousel__edit custom-before" onClick={() => showModal(el)}>
                                <img src={el?.iconURL} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={el?.desc} className="edit-custom" />
                                <h3>{el?.number}</h3>
                                <p>{el?.desc}</p>
                            </div>
                        )}
                    </div>
                </section>
            }
        </>
    )
}

export default EditInfoBannerIAD
