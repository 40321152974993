import React from 'react'
import EditProductSubLines from './EditProductSubLines/EditProductSubLines'

function EditSubLines() {
    return (

        <main>
            <h2 className="EditContent EditContent__title">Sublíneas de productos</h2>
            <EditProductSubLines />
        </main>
    )
}

export default EditSubLines
