import React, {useState, useEffect} from 'react'
import {Fade} from "react-awesome-reveal"
import {useAuthContext} from '../../../../contexts/AuthContext'
import {getIDInfoBanner} from '../../../../services/ApiClient'

import './InfoBanner.css'


function InfoBanner() {

    const [infoData, setInfodata] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const data = await getIDInfoBanner()
            setInfodata(data)

            const counters = document.querySelectorAll('.value')
            const speed = 200

            counters.forEach(counter => {
                const animate = () => {
                    const value = +counter.getAttribute('akhi')
                    const data = +counter.innerText

                    const time = value / speed
                    if (data < value) {
                        counter.innerText = Math.ceil(data + time)
                        setTimeout(animate, 1)
                    } else {
                        counter.innerText = value
                    }

                }

                animate()
            })
        }
        fetchData()

    }, [])

    return (
        <section className="container-fluid InfoBannerID" style={{
            background: `url("${infoData[0]?.backgroundURL}") no-repeat center / cover`
        }}>
            <div className="InfoBannerID-data col-12">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <Fade cascade uration={600} delay={300} triggerOnce>
                                <div className="row">
                                    {infoData[0]?.item?.map(el =>
                                        <div className="col-6 col-sm-3 InfoBanner-aside">
                                            <div className="col-12 text"><span className="value InfoBannerID-colaboradores-icons" akhi={el?.number} style={{
                                                background: `url("${el?.iconURL}") no-repeat left center / contain`
                                            }}>0</span>{el?.unity && language === 'ES' ? el?.unity : el?.unity_eng} <span className="InfoBannerID-colaboradores-texto">{language === 'ES' ? el?.desc : el?.desc_eng}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoBanner
