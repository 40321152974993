import React, {useState, useEffect} from 'react'
import {getMarquee, updateBiocontrolledMarqueeData} from '../../../../services/ApiClient.js'
import {Helmet} from 'react-helmet'
import {useFormState} from '../../../../hooks/useFormState.js'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel.jsx'
import Button from '../../../Form/FormButton/FormButton.jsx'

function EditMarquee() {

    const [registerError, setRegisterError] = useState(null)
    const [marqueeData, setMarqueeData] = useState([])
    const [message, setMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                text: marqueeData?.text,
                text_eng: marqueeData?.text_eng,
            },
            error: {
                text: true,
                text_eng: true,
            },
            touch: {},
        },
        {
            text: v => v.length,
            text_eng: v => v.length,
        }
    )

    const {data, error} = state


    const updateMarquee = async (event) => {
        event.preventDefault()
        data.id = marqueeData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateBiocontrolledMarqueeData(data)
                    .then(info => {
                        setMarqueeData(info)
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getMarqueeData = await getMarquee()
            setMarqueeData(getMarqueeData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>
            <section className="container-fluid EditContent AdminBioletisanLandingPage">
                <h2>Editar texto marquesina</h2>
                <form className="AdminEdit__form" onSubmit={updateMarquee}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.text}
                                label="Texto"
                                onChange={onChange}
                                name="text"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={marqueeData?.text}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.text_eng}
                                label="Text"
                                onChange={onChange}
                                name="text_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={marqueeData?.text_eng}
                                en
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditMarquee

