import React, {useState, useEffect} from 'react'
import {getModified, updateBiocontrolledModifiedData} from '../../../../services/ApiClient.js'
import {Helmet} from 'react-helmet'
import {useFormState} from '../../../../hooks/useFormState.js'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel.jsx'
import Button from '../../../Form/FormButton/FormButton.jsx'
import {Editor} from '@tinymce/tinymce-react'

function EditModified() {

    const [registerError, setRegisterError] = useState(null)
    const [modifiedData, setModifiedData] = useState([])
    const [message, setMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title_one: modifiedData?.title_one,
                title_one_eng: modifiedData?.title_one_eng,
                title_two: modifiedData?.title_two,
                title_two_eng: modifiedData?.title_two_eng,
                title_three: modifiedData?.title_three,
                title_three_eng: modifiedData?.title_three_eng,
                description: modifiedData?.description,
                description_eng: modifiedData?.description_eng,
                description_two: modifiedData?.description_two,
                description_two_eng: modifiedData?.description_two_eng,
            },
            error: {
                title_one: true,
                title_one_eng: true,
                title_two: true,
                title_two_eng: true,
                title_three: true,
                title_three_eng: true,
                description: true,
                description_eng: true,
                description_two: true,
                description_two_eng: true,
            },
            touch: {},
        },
        {
            title_one: v => v.length,
            title_one_eng: v => v.length,
            title_two: v => v.length,
            title_two_eng: v => v.length,
            title_three: v => v.length,
            title_three_eng: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
            description_two: v => v.length,
            description_two_eng: v => v.length,
        }
    )

    const {data, error} = state

    const handleDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const handleDescriptionTwo = (e) => {
        data.description_two = e.target.getContent()
        error.description = false
    }

    const handleDescriptionTwoEng = (e) => {
        data.description_two_eng = e.target.getContent()
        error.description_two_eng = false
    }

    const updateModified = async (event) => {
        event.preventDefault()
        data.id = modifiedData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateBiocontrolledModifiedData(data)
                    .then(info => {
                        setModifiedData(info)
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getModifiedData = await getModified()
            setModifiedData(getModifiedData)
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>
            <section className="container-fluid EditContent AdminBioletisanLandingPage">
                <h2>Editar banner</h2>
                <form className="AdminEdit__form" onSubmit={updateModified}>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.title_one}
                                label="Título uno"
                                onChange={onChange}
                                name="title_one"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={modifiedData?.title_one}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.title_two}
                                label="Título dos"
                                onChange={onChange}
                                name="title_two"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={modifiedData?.title_two}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.title_three}
                                label="Título tres"
                                onChange={onChange}
                                name="title_three"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={modifiedData?.title_three}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.title_one_eng}
                                label="Title one"
                                onChange={onChange}
                                name="title_one_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={modifiedData?.title_one_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.title_two_eng}
                                label="Title two"
                                onChange={onChange}
                                name="title_two_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={modifiedData?.title_two_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputWithLabel
                                value={data.title_three_eng}
                                label="Title three"
                                onChange={onChange}
                                name="title_three_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={modifiedData?.title_three_eng}
                                en
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={modifiedData?.description}
                                onChange={handleDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={modifiedData?.description_eng}
                                onChange={handleDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción dos
                            </p>
                            <Editor
                                initialValue={modifiedData?.description_two}
                                onChange={handleDescriptionTwo}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description two
                            </p>
                            <Editor
                                initialValue={modifiedData?.description_two_eng}
                                onChange={handleDescriptionTwoEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>
                    </div>


                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditModified

