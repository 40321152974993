import './TechCard.css'
import React, {useState, useEffect} from 'react'
import {getTechBioletisan, getPlaceBioletisan} from '../../../../../services/ApiClient'

import {useAuthContext} from '../../../../../contexts/AuthContext'


function TechCard() {

    const [techData, setTechdata] = useState([])
    const [places, setPlaces] = useState([])

    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getTechData = await getTechBioletisan()
            setTechdata(getTechData[0])
            const getPlaceData = await getPlaceBioletisan()
            setPlaces(getPlaceData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container LandingTechCard" id='comprar'>
            <div className="row">
                <div className='col-12 col-sm-6 LandingTechCard--content'>
                    <a href={techData?.link_url}>{language === 'ES' ? techData?.link : techData?.link_eng}</a>
                    <div className='LandingTechCard--title'>
                        <span dangerouslySetInnerHTML={{__html: language === 'ES' ? techData?.description : techData?.description_eng}} />

                        <div className='col-12 col-sm-6 LandingTechCard--image d-block d-sm-none' style={{background: `url(${techData?.bg_url}) no-repeat center / 80%`}}></div>
                    </div>
                    <div className='LandingTechCard--btn'>Comprar</div>
                    <div className='LandingTechCard--places'>
                        {places?.map(el =>
                            <a href={el?.place_link} target="_blank" rel="noreferrer" className='LandingTechCard--place' style={{background: `#f2f2f2 url(${el?.place_url}) no-repeat center / 60%`}}></a>
                        )}
                    </div>
                </div>
                <div className='col-12 col-sm-6 LandingTechCard--image d-none d-sm-block' style={{background: `url(${techData?.bg_url}) no-repeat center / 80%`}}></div>
            </div>
        </section>
    )
}

export default TechCard
