import React, {useState, useEffect} from 'react'
import {getClinical, updateBiocontrolledClinicalData} from '../../../../services/ApiClient.js'
import {Helmet} from 'react-helmet'
import {useFormState} from '../../../../hooks/useFormState.js'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel.jsx'
import Button from '../../../Form/FormButton/FormButton.jsx'
import {Editor} from '@tinymce/tinymce-react'

function EditClinical() {

    const [registerError, setRegisterError] = useState(null)
    const [clinicalData, setClinicalData] = useState([])
    const [message, setMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: clinicalData?.title,
                title_eng: clinicalData?.title_eng,
                description: clinicalData?.description,
                description_eng: clinicalData?.description_eng,
                info: clinicalData?.info,
                info_eng: clinicalData?.info_eng,
                title_two: clinicalData?.title_two,
                title_two_eng: clinicalData?.title_two_eng,
                description_two: clinicalData?.description_two,
                description_two_eng: clinicalData?.description_two_eng,
            },
            error: {
                title: true,
                title_eng: true,
                description: true,
                description_eng: true,
                info: true,
                info_eng: true,
                title_two: true,
                title_two_eng: true,
                description_two: true,
                description_two_eng: true,
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
            info: v => v.length,
            info_eng: v => v.length,
            title_two: v => v.length,
            title_two_eng: v => v.length,
            description_two: v => v.length,
            description_two_eng: v => v.length,
        }
    )

    const {data, error} = state

    const handleDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const handleInfo = (e) => {
        data.info = e.target.getContent()
        error.info = false
    }

    const handleInfoEng = (e) => {
        data.info_eng = e.target.getContent()
        error.info_eng = false
    }

    const handleDescriptionTwo = (e) => {
        data.description_two = e.target.getContent()
        error.description_two = false
    }

    const handleDescriptionTwoEng = (e) => {
        data.description_two_eng = e.target.getContent()
        error.description_two_eng = false
    }

    const updateClinical = async (event) => {
        event.preventDefault()
        data.id = clinicalData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateBiocontrolledClinicalData(data)
                    .then(info => {
                        setClinicalData(info)
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getClinicalData = await getClinical()
            setClinicalData(getClinicalData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Biocontrolled</title>
            </Helmet>
            <section className="container-fluid EditContent AdminBioletisanLandingPage">
                <h2>Editar textos "Ensayos Clínicos", "Procesos" y "Forma dosis"</h2>
                <form className="AdminEdit__form" onSubmit={updateClinical}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title}
                                label="Título ensayos clínicos"
                                onChange={onChange}
                                name="title"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={clinicalData?.title}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title_eng}
                                label="Title"
                                onChange={onChange}
                                name="title_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={clinicalData?.title_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción ensayos clínicos
                            </p>
                            <Editor
                                initialValue={clinicalData?.description}
                                onChange={handleDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={clinicalData?.description_eng}
                                onChange={handleDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Info Procesos
                            </p>
                            <Editor
                                initialValue={clinicalData?.info}
                                onChange={handleInfo}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Info
                            </p>
                            <Editor
                                initialValue={clinicalData?.info_eng}
                                onChange={handleInfoEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title_two}
                                label="Título forma dosis"
                                onChange={onChange}
                                name="title_two"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={clinicalData?.title_two}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title_two_eng}
                                label="Title"
                                onChange={onChange}
                                name="title_two_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={clinicalData?.title_two_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción forma dosis
                            </p>
                            <Editor
                                initialValue={clinicalData?.description_two}
                                onChange={handleDescriptionTwo}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={clinicalData?.description_two_eng}
                                onChange={handleDescriptionTwoEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditClinical

