import React, {useState, useEffect} from 'react'
import './EditProductSubLines.css'
import {useFormState} from '../../../../../hooks/useFormState'
import {getSubLines, deleteSubLine, createSubLine} from '../../../../../services/ApiClient'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'
import Button from '../../../../Form/FormButton/FormButton'

function EditProductSubLines() {

    const [subLinesData, setSubLinesData] = useState([])

    const [message, setMessage] = useState('')
    const [messageCreate, setMessageCreate] = useState('')

    const {state, onBlur, onChange} = useFormState(
        {
            data: {
                subLine: '',
                subLine_eng: '',
            },
            error: {
                subLine: true,
                subLine_eng: true,
            },
            touch: {},
        },
        {
            subLine: v => v.length,
            subLine_eng: v => v.length,
        }
    )

    const {data, error, touch} = state
    const [registerError, setRegisterError] = useState(null)

    const createNewSubLine = async (e) => {
        e.preventDefault()

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await createSubLine(data)
                    .then(subLines => {
                        setSubLinesData(subLines)
                        setMessageCreate('Data atualizada exitosamente')
                        data.subLine = ''
                        data.subLine_eng = ''
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessageCreate('Por favor complete ambos campos')
        }
    }


    const deleteSelectedSubLine = async (id) => {
        const result = await deleteSubLine(id)
        setSubLinesData(result)
        setMessage('Sublínea borrada correctamente.')
    }

    useEffect(() => {
        const fetchData = async () => {
            const getSubLinesData = await getSubLines()
            setSubLinesData(getSubLinesData)
            setMessage('')
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container-fluid EditContent EditProductLines">
                <h2>Crear nuevas sublíneas</h2>
                <form className="AdminEdit__form" onSubmit={createNewSubLine}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Nueva sublínea
                            </p>
                            <InputWithLabel
                                value={data?.subLine}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="subLine"
                                type="text"
                                cssStyle={`form-control mb-3 ${touch.subLine && error.subLine ? "is-invalid" : ""}`}
                                placeholder="Ingresa nueva etiqueta"
                            />
                            <p className="AdminEdit__form__label en">
                                New subline
                            </p>
                            <InputWithLabel
                                value={data?.subLine_eng}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="subLine_eng"
                                type="text"
                                cssStyle={`form-control mb-0 ${touch.subLine_eng && error.subLine_eng ? "is-invalid" : ""}`}
                                placeholder="Ingresa nueva etiqueta"
                            />
                        </div>
                        <div className="col-sm-6 col-12">
                            <Button cssStyle="leti-btn hidden-label AdminEdit__form-leti-btn" >Añadir nueva sublínea</Button>

                            {messageCreate &&
                                <div className="row">
                                    <span className="AdminEdit__message col-12 m-0">{messageCreate}</span>
                                </div>
                            }
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
            <section className="container-fluid EditContent EditProductLines-delete">
                <h2>Eliminar sublíneas</h2>
                <div className="row">
                    {subLinesData?.map(el =>
                        <div onClick={() => deleteSelectedSubLine(el?.id)} className="col-sm-2 col-6 EditProductLines-delete-tags">{el?.subLine}</div>
                    )}
                    <div className="col-12">
                        {message && <span className="AdminEdit__message m-0">{message}</span>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditProductSubLines