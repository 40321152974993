import React, {useState, useEffect} from 'react'
import {useAuthContext} from '../../../../contexts/AuthContext'
import {getEtica} from '../../../../services/ApiClient'
import {Fade} from "react-awesome-reveal"

// import ModalLoadEthics from '../ModalLoadEthics/ModalLoadEthics'
import Loader from '../../../Loader/Loader'
import './Ethics.css'

function Ethics() {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    // const [bool, setBool] = useState(false)
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getEticaData = await getEtica()
            setData(getEticaData)
        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loader />}
            {/* {bool && <ModalLoadEthics hideModal={() => setBool(!bool)} />} */}
            <Fade triggerOnce delay={400} direction="up">
                <section className="container Ethics">
                    <div className="big-gray-triangle"></div>
                    <div className="row justify-content-between">
                        <div className="col-12 col-sm-7">
                            <h1>{language === 'ES' ? data?.title : data?.title_eng}</h1>
                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? data?.description : data?.description_eng}} />
                            <a href={data?.url} target="_blank" rel="noreferrer" className="leti-btn mt-5">{language === 'ES' ? data?.buttonTitle : data?.buttonTitle_eng}</a>
                        </div>
                        <div className="col-12 col-sm-4 d-flex align-items-center">
                            <img src='./images/codigo-etica.png' alt='codigo de etica' className='Ethics__image' />
                        </div>
                    </div>
                </section>
            </Fade>
        </>
    )
}

export default Ethics
