import React, {useState, useEffect} from 'react'

import {workWithUsForm, getModalWork, sendEmailForm} from '../../../../services/ApiClient'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel'
import {useFormState} from '../../../../hooks/useFormState'
import Button from '../../../Form/FormButton/FormButton'
import './ModalWorkWithUs.css'
import {useAuthContext} from '../../../../contexts/AuthContext'
import InputFile from '../../../Form/InputFile/InputFile'
import {app} from '../../../../services/firebase'

function ModalWorkWithUs({hideModal}) {

    const {state, onBlur, onChange} = useFormState(
        {
            data: {
                name: "",
                lastname: "",
                email: "",
                phone: "",
                cv: "",
                linkedin: "",
                country: "",
                city: "",
            },
            error: {
                name: true,
                lastname: true,
                email: true,
                phone: true,
                cv: true,
                linkedin: false,
                country: true,
                city: true,
            },
            touch: {},
        },
        {
            name: v => v.length,
            lastname: v => v.length,
            email: v => v.length,
            phone: v => v.length,
            cv: v => v.length,
            linkedin: v => v.length,
            country: v => v.length,
            city: v => v.length,
        }
    )


    const [modalWorkWithUsData, setModalWorkWithUsData] = useState()
    const [registerError, setRegisterError] = useState(null)
    const [formResponse, setFormResponse] = useState([])
    const [message, setMessage] = useState(false)
    const [imageSuccess, setImageSuccess] = useState('')
    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)

    const {data, error, touch} = state

    const {language} = useAuthContext()

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (error.cv === false || error.name === false || error.lastname === false || error.email === false || error.phone === false || error.country === false || error.city === false) {
            try {
                sendEmailForm(data)
                const newWorkWithUs = await workWithUsForm(data)
                document.querySelector('form').reset()
                document.querySelector('.ModalWorkWithUs__container').classList.add('ModalWorkWithUs__container--success')
                setFormResponse(newWorkWithUs)
                setMessage(!message)
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor rellene los campos obligatorios marcados con *')
        }
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        // Check file type
        if (file.type !== 'application/pdf') {
            setFileSizeMessage('El archivo debe estar en formato PDF')
        } else if (file.size > 500000) {
            setFileSizeMessage(
                'El tamaño del PDF excede el máximo permitido (500KB), por favor optimícelo y vuelva a intentar'
            )
        } else {
            setFileSizeMessage('')
            setIsDisabled(!isDisabled)
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('cvs/' + file.name)

            // Upload file
            await filePath
                .put(file)
                .then(() => {
                    setImageSuccess(
                        language === 'ES' ? 'CV subido correctamente' : 'CV uploaded'
                    )
                })
                .catch((err) => {
                    console.log(err)
                })

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.cv = fileUrl
            setIsDisabled(false)
            error.cv = false
        }
    }


    const clickedOutside = (e) => {
        if (!document.querySelector('.ModalWorkWithUs__container').contains(e.target)) {
            hideModal()
        }
    }

    const isError = Object.values(error).some(err => err)

    useEffect(() => {
        const fetchData = async () => {
            const getModalData = await getModalWork()
            setModalWorkWithUsData(getModalData)
        }
        fetchData()

    }, [])

    return (
        <section className="ModalWorkWithUs" onClick={clickedOutside}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-12 ModalWorkWithUs__container">
                        {message &&
                            <>
                                <h1>{language === 'ES' ? "Gracias" : "Thank you"} {formResponse.name},</h1>
                                <p>{language === 'ES' ? "Tu CV ha sido enviado exitosamente." : "Your CV has been sent successfully."}</p>
                                <div className="leti-blue-triangle"></div>
                                <div className="leti-red-triangle"></div>
                                <div className="leti-btn" onClick={hideModal}>Cerrar</div>
                            </>
                        }
                        {!message &&
                            <>
                                <span className="ModalWorkWithUs__close" onClick={hideModal}></span>
                                <div className="row">
                                    <div className="col-12">
                                        <h1>{language === 'ES' ? modalWorkWithUsData?.title : modalWorkWithUsData?.title_eng}</h1></div>
                                    <div className="col-12 col-sm-5">
                                        <p className="ModalWorkWithUs__desc" dangerouslySetInnerHTML={{__html: language === 'ES' ? modalWorkWithUsData?.description : modalWorkWithUsData?.description_eng}} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <form onSubmit={handleSubmit} className="ModalWorkWithUs__form">
                                            <div className="row justify-content-between">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                value={data.name}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="name"
                                                                type="text"
                                                                label={language === 'ES' ? "Nombre" : "Name"}
                                                                cssStyle={`form-control ${touch.name && error.name ? "is-invalid" : ""}`}
                                                                tabIndex="1"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                value={data.lastname}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="lastname"
                                                                type="text"
                                                                label={language === 'ES' ? "Apellido" : "Surname"}
                                                                cssStyle={`form-control ${touch.lastname && error.lastname ? "is-invalid" : ""}`}
                                                                tabIndex="2"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                value={data.email}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="email"
                                                                type="email"
                                                                label={language === 'ES' ? "Correo electrónico" : "E-mail"}
                                                                cssStyle={`form-control ${touch.email && error.email ? "is-invalid" : ""}`}
                                                                tabIndex="3"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                value={data.phone}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="phone"
                                                                type="text"
                                                                label={language === 'ES' ? "Teléfono" : "Phone"}
                                                                cssStyle={`form-control ${touch.phone && error.phone ? "is-invalid" : ""}`}
                                                                tabIndex="4"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 custom-input">
                                                    <p className="AdminEdit__form__label m-0">
                                                        {language === 'ES' ? "Subir CV en formato PDF" : "Upload CV in PDF format"}
                                                    </p>
                                                    <InputFile
                                                        classStyle="mb-0"
                                                        value=""
                                                        onChange={onFileSelected}
                                                        id="fileButton"
                                                        name="cv"
                                                        type="file"
                                                        placeholder="Subir CV"
                                                        tabIndex="5"
                                                    />
                                                    {imageSuccess && <span className="AdminEdit__message mt-1">{imageSuccess}</span>}
                                                    {
                                                        fileSizeMessage &&
                                                        <div className="col-12">
                                                            <small>{fileSizeMessage}</small>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-4">
                                                            <InputWithLabel
                                                                value={data.linkedin}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="linkedin"
                                                                type="text"
                                                                label={language === 'ES' ? "Perfil de linkedin (no obligatorio)" : "Linkedin profile (not mandatory)"}
                                                                cssStyle="form-control"
                                                                tabIndex="6"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-4">
                                                            <InputWithLabel
                                                                value={data.country}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="country"
                                                                type="text"
                                                                label={language === 'ES' ? "País" : "Country"}
                                                                cssStyle={`form-control ${touch.country && error.country ? "is-invalid" : ""}`}
                                                                tabIndex="7"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-4">
                                                            <InputWithLabel
                                                                value={data.city}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="city"
                                                                type="text"
                                                                label={language === 'ES' ? "Ciudad" : "City"}
                                                                cssStyle={`form-control ${touch.city && error.city ? "is-invalid" : ""}`}
                                                                tabIndex="8"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        cssStyle={`leti-btn ${isError && "disabled"}`}
                                                    >
                                                        {language === 'ES' ? " Enviar" : "Send"}
                                                    </Button>
                                                </div>
                                            </div>

                                            {registerError && <div className="alert alert-danger">{registerError}</div>}
                                        </form>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModalWorkWithUs
