import React, {useState, useEffect} from 'react'
import CookieConsent from "react-cookie-consent"
import {Helmet} from 'react-helmet'

import {getCookieInfo, getSeo} from '../../../services/ApiClient'
import {useAuthContext} from '../../../contexts/AuthContext'

import FarmacoVigilancia from './FarmacoVigilancia/FarmacoVigilancia'
import FindProduct from './FindProduct/FindProduct'
import Portafolio from './Portafolio/Portafolio'
import Carousel from './Carousel/Carousel'
import Unidades from './Unidades/Unidades'
import UsInfo from './UsInfo/UsInfo'
import Video from './Video/Video'

import './Home.css'
// import {Link} from 'react-router-dom/cjs/react-router-dom.min'

function Home() {

    const [cookieInfo, setCookieInfo] = useState('')
    const [cookieInfoEng, setCookieInfoEng] = useState('')
    const [seoInfo, setSeoInfo] = useState('')
    const {language, popup, homePopup} = useAuthContext()


    useEffect(() => {
        const isMenuOpen = document.querySelector('.show')

        if (isMenuOpen) {
            isMenuOpen.classList.remove('show')
        }

        const fetchData = async () => {
            const getCookieInfoData = await getCookieInfo()
            const getSeoData = await getSeo()
            setCookieInfo(getCookieInfoData.info)
            setCookieInfoEng(getCookieInfoData.info_eng)
            const filterSeo = getSeoData.filter(seo => seo.page === 'Inicio')
            setSeoInfo(filterSeo[0])
        }
        fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>{`Grupo LETI | Inicio`}</title>
                <meta name="description" content={`${seoInfo?.description}`} />
                <meta name="keywords" content={`${seoInfo?.keywords}`} />
            </Helmet>
            <main>
                {popup === 'SI' && 
                <div className='popup row' onClick={() => homePopup('NO')}>
                    <div className='popup-content'>
                        {/* <img src='./images/close.svg' className='popup-close' alt='GRUPO LETI' onClick={() => homePopup('NO')}/>
                        <h1>Bienvenido</h1>
                        <p>En nuestra página web podrás conseguir toda la información del Grupo LETI.</p>
                        <p><strong>¡Conócela y explórala!</strong></p>
                        <img src='https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fleti-white.svg?alt=media&token=458af787-ac62-4094-83f9-ab670a72ccef&_gl=1*5baz7v*_ga*MjExMzM0NTU3MS4xNjc1MTg4MDc5*_ga_CW55HF8NVT*MTY4NjEyNzI2MC4yLjEuMTY4NjEyNzY3Mi4wLjAuMA..' alt='Grupo LETI' className='popup-logo'/>
                        <img src='https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Flupa.png?alt=media&token=3f6240fd-0d00-4d50-a05d-edce10bc5db8&_gl=1*1cv2nk9*_ga*MjExMzM0NTU3MS4xNjc1MTg4MDc5*_ga_CW55HF8NVT*MTY4NjEyNzI2MC4yLjEuMTY4NjEyNzg3OS4wLjAuMA..' alt='GRUPO LETI' className='popup-zoom'/> */}
                    </div>
                </div>
                }
                <Video />
                <UsInfo />
                <Carousel />
                <Unidades />
                <Portafolio />
                <FindProduct />
                <FarmacoVigilancia />
                {/* {language === 'ES' &&
                    <Link to={{
                        pathname: `/bioletisan`
                    }} className="Home__fly" />
                } */}
            </main>
            <CookieConsent
                location="bottom"
                buttonText={language === 'ES' ? "Aceptar" : "Accept"}
                cookieName="cookieConsentimiento"
                style={{fontSize: "14px", color: "#fff"}}
                expires={150}
            >
                <div className="container Cookies__container">
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            {language === 'ES' ? <h1>Política de cookies</h1> : <h1>Cookies policy</h1>}
                        </div>
                        <div className="col-12 col-sm-6">
                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? cookieInfo : cookieInfoEng}} />
                        </div>
                    </div>
                </div>
            </CookieConsent>
        </>
    )
}

export default Home