import React, {useState, useEffect} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {getPixelData, updatePixels} from '../../../../../services/ApiClient'
import {useFormState} from '../../../../../hooks/useFormState'
import Button from '../../../../Form/FormButton/FormButton'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'

function EditPixelImage() {

    const [pixelData, setPixelData] = useState()
    const [message, setMessage] = useState('')

    const {state, onBlur, onChange} = useFormState(
        {
            data: {
                id: pixelData?._id,
                facebook1: pixelData?.facebook1,
                facebook2: pixelData?.facebook2,
                google: pixelData?.google,
                google2: pixelData?.google2,
            },
            error: {
                facebook1: true,
                facebook2: true,
                google: true,
                google2: true,
            },
            touch: {},
        },
        {
            facebook1: v => v.length,
            facebook2: v => v.length,
            google: v => v.length,
            google2: v => v.length,
        }
    )

    const {data, error} = state
    const [registerError, setRegisterError] = useState(null)

    const updatePixelData = async (event) => {
        event.preventDefault()
        data.id = pixelData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updatePixels(data)
                    .then(updatedData => {
                        setPixelData(updatedData)
                        setMessage('Data atualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const handleFacebookScript = (e) => {
        data.facebook1 = e.target.getContent({format: "text"})
        error.facebook1 = false
    }

    const handleGoogleScript = (e) => {
        data.google = e.target.getContent({format: "text"})
        error.google = false
    }

    useEffect(() => {
        const fetchData = async () => {
            const getPixelDataInfo = await getPixelData()
            setPixelData(getPixelDataInfo[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <section className="container-fluid EditContent EditContent__active">
            <h2>Pixel image</h2>
            <form className="AdminEdit__form" onSubmit={updatePixelData}>
                <div className="row">
                    <div className="col-12 col-sm-6">

                    <h3>Facebook</h3>
                        <p className="AdminEdit__form__label">
                            Facebook script
                        </p>
                        <Editor
                            initialValue={pixelData?.facebook1}
                            onChange={handleFacebookScript}
                            apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                            init={{
                                height: 200,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount'
                                ],
                                toolbar:
                                    'bold',
                            }}
                        />

                        <p className="AdminEdit__form__label">
                            Facebook noscript
                        </p>
                        <InputWithLabel
                            value={data?.facebook2}
                            onBlur={onBlur}
                            onChange={onChange}
                            name="keywords"
                            type="text"
                            cssStyle="form-control mb-5"
                            placeholder={pixelData?.facebook2}
                        />
                    </div>

                   
                    <div className="col-12 col-sm-6">
                    <h3>Google</h3>
                        <p className="AdminEdit__form__label">
                            Google script
                        </p>
                        <Editor
                            initialValue={pixelData?.google}
                            onChange={handleGoogleScript}
                            apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                            init={{
                                height: 200,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount'
                                ],
                                toolbar:
                                    'bold',
                            }}
                        />
                        <p className="AdminEdit__form__label">
                            Google noscript
                        </p>
                        <InputWithLabel
                            value={data?.google2}
                            onBlur={onBlur}
                            onChange={onChange}
                            name="keywords"
                            type="text"
                            cssStyle="form-control mb-5"
                            placeholder={pixelData?.google2}
                        />
                    </div>
                    <div className="col-12">
                        <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                        {message && <span className="AdminEdit__message">{message}</span>}
                    </div>

                </div>
                {registerError && <div className="alert alert-danger">{registerError}</div>}
            </form>
        </section>
    )
}

export default EditPixelImage
