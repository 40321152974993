import React, {useState, useEffect} from 'react'
import {getFooter} from '../../../../services/ApiClient.js'
import DeleteItemModal from './DeleteItemModal/DeleteItemModal.jsx'

function EditFooter() {

    const [bool, setBool] = useState(false)
    const [modalData, setModalData] = useState([])
    const [footerData, setFooterData] = useState([])

    const deleteItem = (data) => {
        setFooterData(data)
        setBool(!bool)
    }

    const showModal = (data) => {
        setModalData(data)
        setBool(!bool)
    }

    useEffect(() => {
        const fetchData = async () => {
            const getFooterData = await getFooter()
            setFooterData(getFooterData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {bool && <DeleteItemModal hideModal={() => setBool(!bool)} element={modalData} deleteItem={(updateData) => deleteItem(updateData)} />}
            <section className="container-fluid Letilabs EditContent EditContent-timeline">
                <h2>Editar tecnologías</h2>
                <div className="row justify-content-around">
                    {footerData?.map(el =>
                        <div className="col-sm-3 col-12 EditCarousel__edit EditCarousel__edit-force" onClick={() => showModal(el)}>
                            <h4>{el?.title}</h4>
                            <p dangerouslySetInnerHTML={{__html: el?.description}} />
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default EditFooter