import React, {useState} from 'react'

import {deleteSupplierItem, updateSupplierItem} from '../../../../../services/ApiClient'
import {useFormState} from '../../../../../hooks/useFormState'
import './EditInfoDataModal.css'
import InputFile from '../../../../Form/InputFile/InputFile'
import {app} from '../../../../../services/firebase'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'

function EditInfoDataModal({deleteItem, element, hideModal}) {

    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [fileSizeMessage, setFileSizeMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: element.id,
                place_url: element.place_url,
                place_link: element.place_link,
            },
            error: {
                place_url: true,
                place_link: true,
            },
            touch: {},
        },
        {
            place_url: v => v.length,
            place_link: v => v.length,
        }
    )

    const {data, error} = state

    const deleteSuplierItem = async (id) => {
        const updateData = await deleteSupplierItem(id)
        deleteItem(updateData)
    }

    const editSupplierItem = async (id) => {
        if (error.place_url === false || error.place_link === false) {
            data.id = id
            try {
                await updateSupplierItem(data, id)
                    .then(updateData => {
                        deleteItem(updateData)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }

        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    //Se habilita el botón para subir el blog
                    setDisabled(!disabled)
                })
                .catch(err => {
                    console.log(err)
                    setFileSizeMessage(err)
                })


            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.place_url = fileUrl
            setIsDisabled(false)
            error.place_url = false
            setFileSizeMessage("Imagen cargada exitosamente")
        }
    }

    return (
        <div className="EditElementsModal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-8 EditElementsModal__container">
                        <span className="EditElementsModal__close" onClick={hideModal}></span>
                        <div className="col-sm-12">
                            <h1 className="EditInfoDataModal__ask">Editar proveedor</h1>
                            <div className="card">
                                <div className="card-body EditElementsModal__body">
                                    <div className="row align-items-center">
                                        <div className='col-12'>
                                            <p className="AdminEdit__form__label">
                                                Editar logo
                                            </p>
                                            <figure>
                                                <img src={element?.place_url} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt="Proveedor Bioletisan" style={{width: "120px"}} />
                                            </figure>
                                            <InputFile
                                                value={element?.place_url}
                                                onChange={onFileSelected}
                                                id="fileButton"
                                                name="place_url"
                                                type="file"
                                                placeholder={element?.place_url}
                                            />
                                        </div>

                                        {fileSizeMessage &&
                                            <div className='col-12'>
                                                <span className="AdminEdit__message m-0">{fileSizeMessage}</span>
                                            </div>
                                        }
                                        <div className="col-12">
                                            <p className="AdminEdit__form__label">
                                                Editar link
                                            </p>
                                            <InputWithLabel
                                                value={data?.place_link}
                                                onChange={onChange}
                                                name="place_link"
                                                type="text"
                                                cssStyle="form-control"
                                                placeholder={element?.place_link}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div onClick={() => editSupplierItem(element._id)} className="leti-btn mt-0">Editar elemento</div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-flex justify-content-end">
                                            <div onClick={() => deleteSuplierItem(element._id)} className="leti-btn mt-0 delete">Eliminar elemento</div>
                                        </div>
                                        <div className="col-12">
                                            {registerError && <div className="alert alert-danger">{registerError}</div>}
                                            {message && <span className="AdminEdit__message m-0">{message}</span>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditInfoDataModal
