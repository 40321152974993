import React, {useEffect, useState} from 'react'

import './ProspectPage.css'
import {getProductProspect, getProductDataSheet} from "../../../services/ApiClient"
import Loader from "../../Loader/Loader"
import {useAuthContext} from '../../../contexts/AuthContext'

function ProspectPage(props) {

    const [prospect, setProspect] = useState(true)
    const [loading, setLoading] = useState(true)
    const [line, setLine] = useState('')
    const {language} = useAuthContext()

    useEffect(() => {
        window.scrollTo(0, 0)
        const fetchData = async () => {
            const id = props?.location?.state?.data?._id
            const pathname = props?.location?.pathname
            if(pathname.includes("prospecto")) {
                const getProductProspectData = await getProductProspect(id, pathname)
                setProspect(getProductProspectData)
                setLine(getProductProspectData.line)
            } else {
                const getProductDataSheetData = await getProductDataSheet(id, pathname)
                setProspect(getProductDataSheetData)
                setLine(getProductDataSheetData.line)
            }

            setLoading(false)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
            {loading && <Loader />}
            <section className="container ProspectPage">
                <div className="row">
                    <div className="col-12">
                        {loading && <h1>{language === 'ES' ? "CARGANDO" : "LOADING"}</h1>}
                        {language === 'ES' ? 
                        <h1 className="ProspectPage__title">{line === "Ética" || line === "Genven" ? "Prospecto" : "Ficha técnica"} </h1> 
                        :
                        <h1 className="ProspectPage__title">{line === "Ética" || line === "Genven" ? "Prospect" : "Data sheet"} </h1>
                        }
                        <h1 className="ProspectPage__title" dangerouslySetInnerHTML={{__html: prospect?.name}} />
                    </div>
                    <div className="col-12">
                        <p className="ProspectPage__info" dangerouslySetInnerHTML={{__html: prospect?.prospect}} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProspectPage
