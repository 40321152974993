import './DeleteWorkWithUsItem.css'
import React from 'react'
import {deleteWorkWithUsCard} from '../../../../services/ApiClient'

function DeleteWorkWithUsItem({card, hideModal, data}) {

    const deleteCard = async (cardId) => {
        const updatedData = await deleteWorkWithUsCard(cardId)
        hideModal(updatedData)
        data()
    }

    return (
        <div className="DeleteWorkWithUsItem">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-5 DeleteWorkWithUsItem__container">
                        <span className="DeleteWorkWithUsItem__close" onClick={hideModal}></span>
                        <div className="col-sm-12">
                            <p className="DeleteWorkWithUsItem__ask">¿Quieres eliminar el siguiente candidato?</p>
                            <div className="card mb-5" key={card.name}>
                                <div className="card-body">
                                    <span className="AdminWorkWithUsPage__date">{new Date(card.createdAt).getDate()} / {new Date(card.createdAt).getMonth()} / {new Date(card.createdAt).getFullYear()}
                                    </span>
                                    <p className="AdminWorkWithUsPage__medicine">{card.name} {card.lastname}</p>
                                    <div className="AdminWorkWithUsPage__info">
                                        <p className="AdminWorkWithUsPage__datainfo">
                                            <strong>Teléfono</strong> {card.phone}</p>
                                        <p className="AdminWorkWithUsPage__datainfo">
                                            <strong>País / Ciudad</strong> {card.country} / {card.city}</p>
                                        {
                                            card.linkedin && <p className="AdminWorkWithUsPage__datainfo">
                                                <strong>Perfil de Linkedin</strong> {card.linkedin}</p>
                                        }
                                        <p className="AdminWorkWithUsPage__datainfo">
                                            <strong>Email</strong> <a href={`mailto:${card.email}`} className="AdminWorkWithUsPage__email">
                                                {(card.email).toLocaleLowerCase()}
                                            </a></p>
                                    </div>
                                </div>
                            </div>
                            <span onClick={() => deleteCard(card.id)} className="leti-btn">Eliminar</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DeleteWorkWithUsItem
