import './InfoCards.css'
import React, {useState, useEffect} from 'react'
import {Fade} from 'react-awesome-reveal'
import {getOurCompaniesInfoCardsBiocontrolled} from '../../../../services/ApiClient'
import Loader from '../../../Loader/Loader'
import {useAuthContext} from '../../../../contexts/AuthContext'

function InfoCards() {

    const [dataBiocontrolled, setDataBiocontrolled] = useState([])
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getOurCompaniesOCData = await getOurCompaniesInfoCardsBiocontrolled()
            setDataBiocontrolled(getOurCompaniesOCData)
        }
        fetchData()
        setLoading(!loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loader />}
            <section className="container InfoCards InfoCards__custom--margin">
                <div className="row justify-content-around">
                    <Fade className="col InfoCards__Leti__cards" cascade delay={300} direction="up" triggerOnce>
                        {dataBiocontrolled.map(el =>
                            <>
                                <h4>{language === 'ES' ? el?.title : el?.title_eng}</h4>
                                <p dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.info : el?.info_eng}} />
                            </>)}
                    </Fade>
                </div>
            </section>
        </>
    )
}

export default InfoCards
