import './BioletisanLandingPage.css'
import React, {useState, useEffect} from 'react'
import {Helmet} from 'react-helmet'

import {getSeo} from '../../../../services/ApiClient'

import Loader from '../../../Loader/Loader'
import Banner from './Banner/Banner'
import Header from './Header/Header'
import Goals from './Goals/Goals'
import InfoHeader from './InfoHeader/InfoHeader'
import Info from './Info/Info'
import InfoFooter from './InfoFooter/InfoFooter'
import TechCard from './TechCard/TechCard'
import FAQ from './FAQ/FAQ'
import News from './News/News'
import RRSS from './RRSS/RRSS'
import Footer from './Footer/Footer'
import {useAuthContext} from '../../../../contexts/AuthContext'

function BioletisanLandingPage() {

    const [seoInfo, setSeoInfo] = useState('')
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getSeoData = await getSeo()
            const filterSeo = getSeoData.filter(seo => seo.page === 'Bioletisan')
            setSeoInfo(filterSeo[0])
        }
        fetchData()
        setLoading(!loading)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {loading && <Loader />}
            <Helmet>
                <title>{`Grupo LETI | Bioletisan`}</title>
                <meta name="description" content={`${seoInfo?.description}`} />
                <meta name="keywords" content={`${seoInfo?.keywords}`} />
            </Helmet>
            <main>
                <Header />
                <Banner />
                <Goals />
                <InfoHeader />
                <Info />
                <InfoFooter />
                {language === 'ES' && <TechCard />}
                <FAQ />
                <News />
                <RRSS />
                <Footer />
            </main>
        </>
    )
}

export default BioletisanLandingPage
