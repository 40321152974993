import React, {useState, useEffect} from 'react'
import './EditNewsTags.css'
import {useFormState} from '../../../../../hooks/useFormState'
import {getTags, deleteTag, createTag} from '../../../../../services/ApiClient'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'
import Button from '../../../../Form/FormButton/FormButton'

function EditNewsTags() {

    const [tagsData, setTagsData] = useState([])

    const [message, setMessage] = useState('')
    const [messageCreate, setMessageCreate] = useState('')

    const {state, onBlur, onChange} = useFormState(
        {
            data: {
                id: '',
                tag: '',
                tag_eng: ''
            },
            error: {
                tag: true,
                tag_eng: true,
            },
            touch: {},
        },
        {
            tag: v => v.length,
            tag_eng: v => v.length,
        }
    )

    const {data, error, touch} = state
    const [registerError, setRegisterError] = useState(null)


    const createtag = async (e) => {
        e.preventDefault()

        if (error.tag === false && error.tag_eng === false ) {
            try {
                await createTag(data)
                    .then(tags => {
                        setTagsData(tags)
                        setMessageCreate('Data atualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessageCreate('Por favor complete ambos campos')
        }
    }


    const deleteSelectedTag = async (id) => {
        const result = await deleteTag(id)
        setTagsData(result)
        setMessage('Etiqueta borrada correctamente.')
    }

    useEffect(() => {
        const fetchData = async () => {
            const getTagsData = await getTags()
            setTagsData(getTagsData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container-fluid EditContent EditNewsTags">
                <h2>Crear nuevas etiquetas</h2>
                <form className="AdminEdit__form" onSubmit={createtag}>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <p className="AdminEdit__form__label">
                                Nueva etiqueta
                            </p>
                            <InputWithLabel
                                value={data?.tag}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="tag"
                                type="text"
                                cssStyle={`form-control mb-3 ${touch.tag && error.tag ? "is-invalid" : ""}`}
                                placeholder="Ingresa nueva etiqueta"
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <p className="AdminEdit__form__label en">
                                New tag
                            </p>
                            <InputWithLabel
                                value={data?.tag_eng}
                                onBlur={onBlur}
                                onChange={onChange}
                                name="tag_eng"
                                type="text"
                                cssStyle={`form-control mb-0 ${touch.tag_eng && error.tag_eng ? "is-invalid" : ""}`}
                                placeholder="Ingresa nueva etiqueta"
                            />
                        </div>
                        <div className="col-sm-4 col-12">
                            <Button cssStyle="leti-btn hidden-label AdminEdit__form-leti-btn" >Añadir nueva etiqueta</Button>
                            {messageCreate &&
                                <div className="row">
                                    <span className="AdminEdit__message col-12 m-0">{messageCreate}</span>
                                </div>
                            }
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
            <section className="container-fluid EditContent EditNewsTags-delete">
                <h2>Eliminar etiquetas</h2>
                <div className="row">
                    {tagsData?.map(el =>
                        <div onClick={() => deleteSelectedTag(el?.id)} className="col-sm-2 col-6 EditNewsTags-delete-tags">{el?.tag}</div>
                    )}
                    <div className="col-12">
                        {message && <span className="AdminEdit__message m-0">{message}</span>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditNewsTags