export const seoURLPA = (str) => {

    const isHTML = /<[a-z][\s\S]*>/i.test(str);

    if (isHTML) {
        // Crear un elemento temporal para convertir HTML a texto
        const tempElement = document.createElement('div');
        tempElement.innerHTML = str;

        // Obtener el texto sin etiquetas HTML
        str = tempElement.textContent || tempElement.innerText;
    }

    return str?.toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()
        .replace(/&/g, '-and-')
        // eslint-disable-next-line
        .replace(/[^a-z0-9\-]/g, '')
        .replace(/-+/g, '-')
        .replace(/^-*/, '')
        .replace(/-*$/, '')
};

