import React from 'react'
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'

import './AdminBiocontrolledLandingPage.css'
import EditWhoWeAre from './EditWhoWeAre/EditWhoWeAre'
import EditMarquee from './EditMarquee/EditMarquee'
import EditModified from './EditModified/EditModified'
import EditTechTitle from './EditTechTitle/EditTechTitle'
import EditCards from './EditCards/EditCards'
import EditClinical from './EditClinical/EditClinical'
import EditFooter from './EditFooter/EditFooter'

function AdminBiocontrolledLandingPage() {

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Biocontrolled</title>
            </Helmet>

            <main className="container-fluid">
                <Link to={{
                    pathname: `/biocontrolled-info`
                }} className="AdminBioletisanLandingPage--link" target="_blank">Ver landing page</Link>
                <EditWhoWeAre />
                <EditMarquee />
                <EditModified />
                <EditTechTitle />
                <EditCards />
                <EditClinical />
                <EditFooter />
            </main>
        </>
    )
}

export default AdminBiocontrolledLandingPage

