import './FindProduct.css'
import React, {useState, useEffect} from 'react'
import {Slide} from 'react-awesome-reveal'
import {useHistory} from 'react-router'

import DropdownWithLabel from '../../../Form/DropdownWithLabel/DropdownWithLabel'
import Loader from '../../../Loader/Loader'

import {getVadevecumData} from '../../../../services/ApiClient'
import {useFormState} from '../../../../hooks/useFormState'
import {useAuthContext} from '../../../../contexts/AuthContext'
import {seoURLPA} from "../../../../hooks/seoURLPA";


function FindProduct() {

    const {state, onChange} = useFormState(
        {
            data: {
                search: "",
                especialidad: ""
            },
            error: {
                search: true,
                especialidad: true
            },
            touch: {},
        },
        {
            search: v => v.length,
            especialidad: v => v.length
        }
    )

    const [vadevecum, setVadevecum] = useState([])
    const [loading, setLoading] = useState(true)
    const {language} = useAuthContext()

    const {data, error, touch} = state

    let history = useHistory()

    const searchSubmit = (event) => {
        event.preventDefault()

        const producto = data?.search

        const objetoEncontrado = vadevecum.find(objeto => 
            producto.toLowerCase().startsWith(objeto.name.toLowerCase())
          );
        const active = objetoEncontrado?.active_principle;

        if (objetoEncontrado) {
            let pathname = "";
            if(objetoEncontrado?.subLine === "Dolor/SNC" || objetoEncontrado?.subLine === "Cardiometabólica") {
                pathname = `${seoURLPA(objetoEncontrado?.name)}-`
            }
            history.push({
                pathname: `/producto-${pathname}${seoURLPA(active)}${objetoEncontrado?.line === "Genven" ? "-genven" : ""}`,
                state: {
                    buscar: data.search,
                    especialidad: data.especialidad
                }
            })
        } else {
            history.push({
                pathname: `/no-encontrado`
            })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await getVadevecumData()
            setVadevecum(data)
        }
        fetchData()
        setLoading(!loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {loading && <Loader />}
            <Slide direction="up" triggerOnce>
                <section className="container-fluid FindProduct">
                    <div className="container">
                        <div className="row FindProduct__row">
                            <div className="col-12 col-sm-10">
                                {language === 'ES' ? <h1>Encuentra un producto</h1> : <h1>Find a product</h1>}
                                <form className="FindProduct__form">
                                    <div className="input-group">
                                        <div className="col-12 p-0 col-sm-11 FindProduct__label">
                                            <DropdownWithLabel
                                                placeholder={language === 'ES' ? "Escribe nombre o condición" : "Type name or condition"}
                                                value={data.search}
                                                label=""
                                                name="search"
                                                onChange={onChange}
                                                cssStyle={`product form-control ${touch.search && error.search ? "is-invalid" : ""}`}
                                                list="searchs"
                                                data={[...new Set(vadevecum.map(v => v.name))].sort()}
                                            />
                                        </div>
                                        {/* <div className="col-12 p-0 col-sm-4">
                                            <DropdownWithLabel
                                                placeholder= {language === 'ES' ? "Especialidad médica" : "Medical speciality"}
                                                value={data.especialidad}
                                                label=""
                                                name="especialidad"
                                                onChange={onChange}
                                                cssStyle={`category form-control ${touch.especialidad && error.especialidad ? "is-invalid" : ""}`}
                                                list="especialidades"
                                                data={language === 'ES' ? dataSpecialities : dataSpecialitiesEng}
                                            />
                                        </div> */}
                                        <div onClick={searchSubmit} className="col-12 p-0 col-sm-1 leti-btn">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </section>
            </Slide>
        </>
    )
}

export default FindProduct
