import React, {useState, useEffect} from 'react'
import {Editor} from '@tinymce/tinymce-react'
import {keyframes} from "@emotion/react"
import {Helmet} from 'react-helmet'

import {addHomeScreen, getVadevecumData, addProductApi, getLines, getSubLines} from '../../../services/ApiClient'
import {useFormState} from '../../../hooks/useFormState'
import {app} from '../../../services/firebase'
import {Reveal} from "react-awesome-reveal"

import InputWithLabel from '../../Form/InputWithLabel/InputWithLabel'
import ShowEditModal from './ShowEditModal/ShowEditModal'
import InputFile from '../../Form/InputFile/InputFile'
import Button from '../../Form/FormButton/FormButton'
import Loader from '../../Loader/Loader'

import './AdminProductPage.css'
import DropdownWithLabel from '../../Form/DropdownWithLabel/DropdownWithLabel'
import SwitchWithLabel from '../../Form/SwitchWithLabel/SwitchWithLabel'
import {seoURLPA} from "../../../hooks/seoURLPA";

function AdminProductPage() {

    const {state, onBlur, onChange} = useFormState(
        {
            data: {
                name: "",
                line: "",
                subline: "",
                health_register: "",
                picPath: "",
                QRpath: "",
                active_principle: "",
                posology: "",
                presentation: "",
                composition: "",
                indication: "",
                therapeutic_group: "",
                prospect: "",
                show_in_products: true,
                name_eng: "",
                line_eng: "",
                health_register_eng: "",
                active_principle_eng: "",
                posology_eng: "",
                presentation_eng: "",
                composition_eng: "",
                indication_eng: "",
                therapeutic_group_eng: "",
                prospect_eng: "",
                supplierFarmatodoLink: "",
                supplierLocatelLink: "",
                supplierFarmadonLink: "",
                supplierFarmabienLink: "",
                url: ""
            },
            error: {
                name: true,
                line: true,
                subline: false,
                health_register: true,
                picPath: true,
                QRpath: true,
                posology: true,
                active_principle: true,
                presentation: true,
                composition: true,
                indication: true,
                therapeutic_group: true,
                prospect: true,
                show_in_products: true,
                name_eng: true,
                line_eng: true,
                health_register_eng: true,
                posology_eng: true,
                active_principle_eng: true,
                presentation_eng: true,
                composition_eng: true,
                indication_eng: true,
                therapeutic_group_eng: true,
                prospect_eng: true,
                supplierFarmatodoLink: false,
                supplierLocatelLink: false,
                supplierFarmadonLink: false,
                supplierFarmabienLink: false,
                url: false
            },
            touch: {},
        },
        {
            name: v => v.length,
            line: v => v.length,
            subline: v => v.length,
            health_register: v => v.length,
            picPath: v => v.length,
            QRpath: v => v.length,
            active_principle: v => v.length,
            posology: v => v.length,
            presentation: v => v.length,
            composition: v => v.length,
            indication: v => v.length,
            therapeutic_group: v => v.length,
            prospect: v => v.length,
            show_in_products: v => v.length,
            name_eng: v => v.length,
            line_eng: v => v.length,
            health_register_eng: v => v.length,
            active_principle_eng: v => v.length,
            posology_eng: v => v.length,
            presentation_eng: v => v.length,
            composition_eng: v => v.length,
            indication_eng: v => v.length,
            therapeutic_group_eng: v => v.length,
            prospect_eng: v => v.length,
            supplierFarmatodoLink: v => v.length,
            supplierLocatelLink: v => v.length,
            supplierFarmadonLink: v => v.length,
            supplierFarmabienLink: v => v.length,
            url: v => v.length
        }
    )

    const {data, error, touch} = state

    const [createProduct, setCreateProduct] = useState(false)
    const [registerError, setRegisterError] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [checked, setChecked] = useState(true)
    const [loading, setLoading] = useState(true)
    const [bool, setBool] = useState(false)

    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [imageSuccess, setImageSuccess] = useState('')
    const [firebaseUrl, setFirebaseUrl] = useState('')
    const [editProduct, setEditProduct] = useState('')
    const [message, setMessage] = useState('')
    const [search, setSearch] = useState('')

    const [products, setProducts] = useState([])
    const [filter, setFilter] = useState([])
    const [lines, setLines] = useState([])
    const [subLines, setSubLines] = useState([])

    const createNewProduct = async (event) => {
        event.preventDefault()
        if (error.therapeutic_group === false) {
            if (data.therapeutic_group.includes(",")) {
                const setCategories = data.therapeutic_group.split(',')
                data.therapeutic_group = setCategories
            }
        }

        if (error.name === false && error.line === false && error.health_register === false && error.active_principle === false && error.posology === false && error.presentation === false && error.composition === false && error.indication === false && error.therapeutic_group === false) {

            let pathname = "";
            if(data.subLine === "Dolor/SNC" || data.subLine === "Cardiometabólica") {
                pathname = `${seoURLPA(data.name)}-`
            }
            let active_principle_url = seoURLPA(data.active_principle)
            let genven = "";
            if(data.line === "Genven") {
                genven = "-genven"
            }
            const url = `${pathname}${active_principle_url}${genven}`
            data.url = url
            try {
                const newProduct = await addProductApi(data)
                setProducts(newProduct)
                setMessage('Producto creado con éxito')
                setCreateProduct(!createProduct)
                setMessage('')
                data.name = ''
                data.line = ''
                data.subline = ''
                data.health_register = ''
                data.therapeutic_group = ''
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor rellene todos los campos necesarios')
        }
    }

    const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0rem, -5rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }`

    const handleChange = (e) => {
        setSearch(e.target.value)
    }

    const filteredProducts = products.filter(el => {
        return (
            el.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        )
    })

    const showAtHome = async (e, id) => {
        if (products.filter(el => el?.show_in_home === true).length >= 18 && e.target.checked === true) {
            setMessage('Ya se han alcanzado el máximo de productos para mostrar en la página principal. Por favor, desmarque alguno para continuar.')
        } else {
            setMessage('')
            const res = await addHomeScreen(e.target.checked, id)
            setProducts(res)
        }
    }

    const showModal = (product) => {
        setBool(!bool)
        setEditProduct(product)
    }

    const hideModal = () => {
        setBool(!bool)
    }

    const updateData = (data) => {
        setBool(!bool)
        setProducts(data)
    }

    const showAddNewForm = () => {
        setFirebaseUrl('')
        setCreateProduct(!createProduct)
    }

    const onFileSelected = async (e) => {

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setImageSuccess('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            setImageSuccess('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    //console.log('Uploaded')
                })
                .catch(err => {setMessage(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data[e.target.name] = fileUrl
            error[e.target.name] = false
            setImageSuccess("Imagen subida correctamente")
            setIsDisabled(false)
        }
    }

    const onFirebaseSelected = async (e) => {
        setFirebaseUrl('')

        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setImageSuccess('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setFileSizeMessage('')
            setImageSuccess('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    //console.log('Uploaded')
                })
                .catch(err => {setMessage(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()

            error[e.target.name] = false
            setFirebaseUrl(fileUrl)
            setIsDisabled(false)
        }
    }

    const handleEditor = (e) => {
        data[e.target.settings.name] = e.target.getContent()
        error[e.target.settings.name] = false
    }

    const handleContent = (e) => {
        data.prospect = e.target.getContent()
        error.prospect = false
    }

    const carouselHomeProducts = async (e) => {
        e.target.checked ?
            setProducts(products.filter(el => el?.show_in_home === true))
            :
            setProducts(filter)
    }

    const setShow = (e) => {
        setChecked(!checked)
        error.show_in_products = false
        e.target.checked ? data.show_in_products = true : data.show_in_products = false
    }

    useEffect(() => {
        const fetchData = async () => {
            const allLines = await getLines()
            const allSubLines = await getSubLines()
            const allProducts = await getVadevecumData()
            setLines(allLines)
            setSubLines(allSubLines)
            setProducts(allProducts)
            setFilter(allProducts)
            setLoading(false)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {loading && <Loader message="Cargando productos..." />}
            {isDisabled && <Loader message="Cargando imagen..." />}
            {bool && <ShowEditModal lines={lines} subLines={subLines} product={editProduct} hideModal={hideModal} updateData={(data) => updateData(data)} />}
            <Helmet>
                <title>Grupo LETI | Administrador Productos</title>
            </Helmet>
            <main className="container-fluid AdminProductPage">
                <div className="row">
                    <div className="col-12 AdminProductPage__bg">
                        <div className="container">
                            <input type="text" className="form-control AdminProductPage__search" placeholder="Filtrar por producto" onChange={handleChange} value={search} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <button className="AdminProductPage__add" onClick={showAddNewForm}>Añadir nuevo producto</button>
                                </div>
                            </div>
                            {!filteredProducts.length &&
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="mb-5">El producto que busca no se encuentra.</h1>
                                    </div>
                                </div>
                            }
                            {createProduct &&
                                <Reveal triggerOnce keyframes={customAnimation} duration={600} className="row">
                                    <><span className="ShowEditModal__close big" onClick={() => setCreateProduct(false)}></span>
                                        <div className={`col-12 AdminProductPage__create ${createProduct && 'show'}`}>
                                            <h1>Crear producto</h1>
                                            <small>* Campos obligatorios</small>
                                            <form className="AdminEdit__form" onSubmit={createNewProduct}>
                                                <div className="row">
                                                    <div className='col-12'>
                                                        <SwitchWithLabel
                                                            checked={checked}
                                                            onChange={setShow}
                                                            text={'producto'}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <InputFile
                                                            label="Imagen producto"
                                                            value={data.picPath}
                                                            onChange={onFileSelected}
                                                            id="fileButton"
                                                            name="picPath"
                                                            type="file"
                                                        />
                                                        {imageSuccess && <small
                                                            className="img-success col-12">{imageSuccess}</small>}
                                                    </div>
                                                    {/* <div className="col-12 col-sm-6">
                                                        <InputFile
                                                            label="QR producto"
                                                            value={data.QRpath}
                                                            onChange={onFileSelected}
                                                            id="fileButton"
                                                            name="QRpath"
                                                            type="file"
                                                        />
                                                    </div> */}
                                                    {fileSizeMessage &&
                                                        <div className="col-12">
                                                            <small>{fileSizeMessage}</small>
                                                        </div>
                                                    }
                                                    <div className="col-12 col-sm-4">
                                                        <InputWithLabel
                                                            label="Nombre *"
                                                            value={data.name}
                                                            onBlur={onBlur}
                                                            onChange={onChange}
                                                            name="name"
                                                            type="text"
                                                            placeholder="Ingresa nombre del producto"
                                                            cssStyle={`form-control ${touch.name && error.name ? "is-invalid" : ""}`}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <DropdownWithLabel
                                                            value={data.line}
                                                            label="Línea *"
                                                            name="line"
                                                            onChange={onChange}
                                                            cssStyle={`form-control ${touch.line && error.line ? "is-invalid" : ""}`}
                                                            tabIndex="6"
                                                            list="lines"
                                                            data={[...new Set(lines.map(v => v.line))].sort()}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <DropdownWithLabel
                                                            value={data.subLine}
                                                            label="SubLínea"
                                                            name="subLine"
                                                            onChange={onChange}
                                                            cssStyle={`form-control ${touch.line && error.line ? "is-invalid" : ""}`}
                                                            tabIndex="6"
                                                            list="subLines"
                                                            data={[...new Set(subLines.map(v => v.subLine))].sort()}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <InputWithLabel
                                                            label="Registro sanitario *"
                                                            value={data.health_register}
                                                            onBlur={onBlur}
                                                            onChange={onChange}
                                                            name="health_register"
                                                            type="text"
                                                            cssStyle={`form-control ${touch.health_register && error.health_register ? "is-invalid" : ""}`}
                                                            placeholder="Registro sanitario"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <InputWithLabel
                                                            label="Áreas terapéuticas/Separadas por coma *"
                                                            value={data.therapeutic_group}
                                                            onBlur={onBlur}
                                                            onChange={onChange}
                                                            name="therapeutic_group"
                                                            type="text"
                                                            cssStyle={`form-control ${touch.therapeutic_group && error.therapeutic_group ? "is-invalid" : ""}`}
                                                            placeholder="Áreas terapéuticas separadas por coma"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <p className="label">Composición *</p>
                                                        <Editor
                                                            onChange={handleEditor}
                                                            apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                            init={{
                                                                name: 'composition',
                                                                placeholder: "Ingresa composición del producto",
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount'
                                                                ],
                                                                toolbar:
                                                                    'bold',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <p className="label">Principio activo *</p>
                                                        <Editor
                                                            onChange={handleEditor}
                                                            apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                            init={{
                                                                name: "active_principle",
                                                                placeholder: "Ingresa principio(s) activo(s) del producto",
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount'
                                                                ],
                                                                toolbar:
                                                                    'bold',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <p className="label">Posología *</p>
                                                        <Editor
                                                            onChange={handleEditor}
                                                            apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                            init={{
                                                                name: "posology",
                                                                placeholder: "Ingresa posología del producto",
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount'
                                                                ],
                                                                toolbar:
                                                                    'bold',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <p className="label">Presentación *</p>
                                                        <Editor
                                                            onChange={handleEditor}
                                                            apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                            init={{
                                                                name: "presentation",
                                                                placeholder: "Ingresa presentación del producto",
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount'
                                                                ],
                                                                toolbar:
                                                                    'bold',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <p className="label">Indicaciones *</p>
                                                        <Editor
                                                            onChange={handleEditor}
                                                            apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                            init={{
                                                                name: "indication",
                                                                placeholder: "Ingresa indicaciones del producto",
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount'
                                                                ],
                                                                toolbar:
                                                                    'bold',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="label">Prospecto *</p>
                                                            <Editor
                                                                onChange={handleContent}
                                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                                init={{
                                                                    name: "prospect",
                                                                    placeholder: "Ingresa texto del prospecto",
                                                                    height: 500,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist autolink lists link image charmap print preview anchor',
                                                                        'searchreplace visualblocks code fullscreen',
                                                                        'insertdatetime media table paste code help wordcount'
                                                                    ],
                                                                    toolbar: 'undo redo | formatselect | ' +
                                                                        'bold italic | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'table image | help',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <InputFile
                                                                label="Imagen para insertar en prospecto"
                                                                value=""
                                                                onChange={onFirebaseSelected}
                                                                id="fileButton"
                                                                name="firebasePath"
                                                                type="file"
                                                            />
                                                            {firebaseUrl &&
                                                                <>
                                                                    <small className="firebase-copy col-12">Copiar URL y
                                                                        pegar en el campo de imagen del editor del
                                                                        prospecto</small>
                                                                    <small
                                                                        className="firebase col-12">{firebaseUrl}</small>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                label="Url del producto en Farmatodo"
                                                                value={data.supplierFarmatodoLink}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="supplierFarmatodoLink"
                                                                type="text"
                                                                cssStyle="form-control"
                                                                placeholder="Ingresar URL con prefijo http"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                label="Url del producto en Locatel"
                                                                value={data.supplierLocatelLink}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="supplierLocatelLink"
                                                                type="text"
                                                                cssStyle="form-control"
                                                                placeholder="Ingresar URL con prefijo http"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                label="Url del producto en Farmadon"
                                                                value={data.supplierFarmadonLink}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="supplierFarmadonLink"
                                                                type="text"
                                                                cssStyle="form-control"
                                                                placeholder="Ingresar URL con prefijo http"
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <InputWithLabel
                                                                label="Url del producto en Farmabien"
                                                                value={data.supplierFarmabienLink}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                name="supplierFarmabienLink"
                                                                type="text"
                                                                cssStyle="form-control"
                                                                placeholder="Ingresar URL con prefijo http"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-5">
                                                        <Button type="submit" cssStyle="leti-btn">Crear
                                                            producto</Button>
                                                    </div>
                                                </div>

                                                {registerError &&
                                                    <div className="alert alert-danger">{registerError}</div>}

                                                {message && <div className="alert-message">{message}</div>}
                                            </form>
                                        </div>
                                    </>
                                </Reveal>
                            }
                            {filteredProducts.length > 0 &&
                                <div className="row">
                                    <div className="col-12 AdminProductPage__showproducts">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                   id="flexCheckDefault"
                                                   onChange={carouselHomeProducts}/>
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Mostrar sólo los productos del carrusel del home.<br />
                                                <small><strong>*Seleccionar mínimo 4 productos y máximo 18 para mostrar en el carrusel del home.</strong></small>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {filteredProducts.map(el =>
                                    <div className="col-sm-4 col-12">
                                        <div className="card AdminProductPage__card">
                                            <div className="card-body">
                                                <img src={el?.picPath} className="AdminProductPage__img-top" onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={el?.name} />
                                                {/* {el?.QRpath &&
                                                    <img src={el?.QRpath} className="AdminProductPage__img-bottom" onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={el?.name} />} */}
                                            </div>
                                            <div className="card-body">
                                                <h5 dangerouslySetInnerHTML={{__html: el?.line}}>
                                                </h5>
                                                <p className="card-text" dangerouslySetInnerHTML={{__html: el?.name}} />
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item AdminProductPage__check">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={el.show_in_home} onChange={(e) => showAtHome(e, el._id)} />
                                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                                            mostrar en carrusel del home<strong>*</strong>
                                                        </label>
                                                    </div></li>
                                                <span className="card-title">Composición</span><li className="list-group-item" dangerouslySetInnerHTML={{__html: el?.composition}} />
                                                <span className="card-title">Principio activo</span><li className="list-group-item" dangerouslySetInnerHTML={{__html: el?.active_principle}} />
                                                <span className="card-title">Posología</span><li className="list-group-item" dangerouslySetInnerHTML={{__html: el?.posology}} />
                                                <span className="card-title">Presentación</span>
                                                <li className="list-group-item" dangerouslySetInnerHTML={{__html: el?.presentation}} />
                                                <span className="card-title">Registro sanitario</span>
                                                <li className="list-group-item" dangerouslySetInnerHTML={{__html: el?.health_register}} />
                                                {el?.trademarks &&
                                                    <>
                                                        <span className="card-title">Trademarks</span>
                                                        <li className="list-group-item" dangerouslySetInnerHTML={{__html: el?.trademarks}} />
                                                    </>
                                                }
                                                <span className="card-title">Indicaciones</span>
                                                <li className="list-group-item" dangerouslySetInnerHTML={{__html: el?.indication}} />
                                            </ul>
                                            <div className="card-footer">
                                                <div onClick={() => showModal(el)} className="leti-btn">Editar producto</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminProductPage


