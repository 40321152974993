import './FarmacoVigilancia.css'
import React, {useState} from 'react'

import ModalFarmacoVigilancia from '../../HomePage/ModalFarmacoVigilancia/ModalFarmacoVigilancia'
import {Fade} from 'react-awesome-reveal'
import {useAuthContext} from '../../../../contexts/AuthContext'


function FarmacoVigilancia({info}) {

    const [bool, setBool] = useState(false)
    const {language} = useAuthContext()

    const showModal = () => {
        setBool(!bool)
    }

    return (
        <>
            <Fade direction="up" triggerOnce>
                <section className="container FarmacoVigilancia__form">
                    <div className="row justify-content-center">
                        <div className="col-11 col-sm-6 FarmacoVigilancia__texto">
                            <strong>{language === 'ES' ? info?.title : info?.title_eng}</strong>
                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? info?.subTitle : info?.subTitle_eng}} />
                        </div>
                        <div className="col-11 col-sm-6 d-flex align-items-center">
                            <div className="leti-btn" onClick={showModal}>{language === 'ES' ? info?.buttonTitle :info?.buttonTitle_eng}</div>
                        </div>
                    </div>
                </section>
            </Fade>
            {bool && <ModalFarmacoVigilancia hideModal={() => setBool(!bool)} />}
        </>
    )
}

export default FarmacoVigilancia
