import './Goals.css'
import React, {useState, useEffect} from 'react'
import {getGoalsBioletisan} from '../../../../../services/ApiClient'
import {Fade} from 'react-awesome-reveal'

import {useAuthContext} from '../../../../../contexts/AuthContext'


function Goals() {

    const [bannerData, setBannerData] = useState([])

    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getBannerData = await getGoalsBioletisan()
            setBannerData(getBannerData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container-fluid LandingGoals">
            <div className="row">
                <div className='col-12 col-sm-6 LandingGoals--image' style={{background: `url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fmano-pastilla.png?alt=media&token=a333f355-6c81-4c70-b7fb-b37b92ffd3d8) no-repeat top -100px left / cover`}}></div>
                <div className='col-12 col-sm-6 LandingGoals--content'>
                    <h1 className='LandingGoals--title' dangerouslySetInnerHTML={{__html: language === 'ES' ? bannerData[0]?.title : bannerData[0]?.title_eng}} />
                    <ul className='LandingGoals--list'>
                        <Fade cascade direction="down" triggerOnce>
                            {bannerData?.map(el =>
                                <li>
                                    <span className='icon' style={{background: `white url(${el?.icon_url}) no-repeat center / contain`}} /><div dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.description : el?.description_eng}} />
                                </li>
                            )}
                        </Fade>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Goals
