import React, {useState, useEffect} from 'react'
import {getWhoWeAre, updateBiocontrolledWhoWeAreData} from '../../../../services/ApiClient'
import {Helmet} from 'react-helmet'
import {useFormState} from '../../../../hooks/useFormState.js'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel'
import Button from '../../../Form/FormButton/FormButton'
import {Editor} from '@tinymce/tinymce-react'

function EditWhoWeAre() {

    const [registerError, setRegisterError] = useState(null)
    const [whoWeAreData, setWhoWeAreData] = useState([])
    const [message, setMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: whoWeAreData?.title,
                title_eng: whoWeAreData?.title_eng,
                description: whoWeAreData?.description,
                description_eng: whoWeAreData?.description_eng,
            },
            error: {
                title: true,
                title_eng: true,
                description: true,
                description_eng: true,
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
        }
    )

    const {data, error} = state

    const handleTechDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleTechDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const updateWhoWeAre = async (event) => {
        event.preventDefault()
        data.id = whoWeAreData._id

        if (Object.values(error).map(el => el).includes(false)) {
            try {
                await updateBiocontrolledWhoWeAreData(data)
                    .then(info => {
                        setWhoWeAreData(info)
                        setMessage('Data actualizada exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getWhoWeAreData = await getWhoWeAre()
            setWhoWeAreData(getWhoWeAreData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Biocontrolled</title>
            </Helmet>
            <section className="container-fluid EditContent AdminBioletisanLandingPage">
                <h2>Editar quienes somos</h2>
                <form className="AdminEdit__form" onSubmit={updateWhoWeAre}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title}
                                label="Título"
                                onChange={onChange}
                                name="title"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={whoWeAreData?.title}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.title_eng}
                                label="Title"
                                onChange={onChange}
                                name="title_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder={whoWeAreData?.title_eng}
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={whoWeAreData?.description}
                                onChange={handleTechDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Description
                            </p>
                            <Editor
                                initialValue={whoWeAreData?.description_eng}
                                onChange={handleTechDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Guardar cambios</Button>
                            {message && <span className="AdminEdit__message">{message}</span>}
                        </div>
                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditWhoWeAre

