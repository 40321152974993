import './FAQ.css'
import React, {useState, useEffect} from 'react'
import {getFaq, getFaqItems} from '../../../../../services/ApiClient'

import {useAuthContext} from '../../../../../contexts/AuthContext'


function FAQ() {

    const [faqData, setFaqData] = useState()
    const [itemData, setItemData] = useState([])

    const {language} = useAuthContext()

    const collapseItem = (e) => {
        const target = e.currentTarget.getAttribute('data-target')

        const element = document.querySelector(target)

        const collapsibleElements = document.querySelectorAll('.collapse')

        collapsibleElements.forEach(el => {
            if (el !== element && el.classList.contains('show')) {
                el.style.height = `${el.scrollHeight}px`
                requestAnimationFrame(() => {
                    el.style.height = '0'
                })
                el.addEventListener('transitionend', () => {
                    el.classList.remove('show')
                    el.style.height = ''
                }, {once: true})
            }
        })

        if (element) {
            if (element.classList.contains('show')) {
                element.style.height = `${element.scrollHeight}px`
                requestAnimationFrame(() => {
                    element.style.height = '0'
                })
                element.addEventListener('transitionend', () => {
                    element.classList.remove('show')
                    element.style.height = ''
                }, {once: true})
            } else {
                element.classList.add('show')
                element.style.height = '0'
                requestAnimationFrame(() => {
                    element.style.height = `${element.scrollHeight}px`
                })
                element.addEventListener('transitionend', () => {
                    element.style.height = ''
                }, {once: true})
            }
        } else {
            console.log('Elemento no encontrado')
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            const getFaqData = await getFaq()
            setFaqData(getFaqData[0])
            const getFaqItemsData = await getFaqItems()
            setItemData(getFaqItemsData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container LandingFAQ" id="faq">
            <div className="row justify-content-between">
                <div className='col-12 col-sm-3 LandingFAQ--content'>
                    <div className='LandingFAQ--title' dangerouslySetInnerHTML={{__html: language === 'ES' ? faqData?.title : faqData?.title_eng}} />
                    <div className='LandingFAQ--subtitle d-none d-sm-block'>
                        <span dangerouslySetInnerHTML={{__html: language === 'ES' ? faqData?.description : faqData?.description_eng}} /><br />
                        <a href={`mailto:${faqData?.link_url}`}  dangerouslySetInnerHTML={{__html: language === 'ES' ? faqData?.link : faqData?.link_eng}} />
                    </div>
                </div>
                <div className='col-12 col-sm-8 LandingFAQ--accordion'>
                    <div id="accordion">
                        {itemData?.map((el, index) => {
                            const headingId = `heading${index}`
                            const collapseId = `collapse${index}`

                            return (
                                <div className="card" key={index}>
                                    <div className="card-header" id={headingId}>
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-link"
                                                data-toggle="collapse"
                                                data-target={`#${collapseId}`}
                                                aria-expanded="true"
                                                aria-controls={collapseId}
                                                onClick={collapseItem}
                                            >
                                                {language === 'ES' ? el?.title : el?.title_eng}
                                            </button>
                                        </h5>
                                    </div>

                                    <div id={collapseId} className="collapse" aria-labelledby={headingId} data-parent="#accordion">
                                        <div className="card-body" dangerouslySetInnerHTML={{__html: language === 'ES' ? el?.description : el?.description_eng}} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='LandingFAQ--subtitle d-block d-sm-none'>
                        <span dangerouslySetInnerHTML={{__html: language === 'ES' ? faqData?.description : faqData?.description_eng}} /><br />
                        <a href={faqData?.link_url} dangerouslySetInnerHTML={{__html: language === 'ES' ? faqData?.link : faqData?.link_eng}} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ
