import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'

import {getProduct, getProductDataFromActivePrinciple} from '../../../services/ApiClient'

import './ProductNotFound.css'
import {useAuthContext} from '../../../contexts/AuthContext'

function ProductNotFound(props) {

    const buscar = props?.location?.state?.buscar
    const [productName, setProductName] = useState('')
    const {language} = useAuthContext()

    useEffect(() => {
        window.scrollTo(0, 0)
        const fetchData = async () => {
            const pathname = props?.location?.pathname?.slice(1)
            if (buscar !== undefined) {
                const getProductData = await getProduct(buscar)
                setProductName(getProductData[0][0].name)
            } else {
                const getProductData = await getProductDataFromActivePrinciple(pathname)
                setProductName(getProductData[0][0].name)
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buscar])

    return (
        <>
            <Helmet>
                <title>{`Grupo LETI | Producto | ${productName}`}</title>
                <meta name="description" content={`Resultado de búsqueda de producto del Grupo LETI: ${productName}`} />
                <meta name="keywords" content={`Grupo LETI, productos leti, ${productName}`} />
            </Helmet>
            <main className="container NotFoundPage ProductNotFound">
                <div className="row justify-content-center">
                    <div className="col-12 NotFoundPage__icon"></div>
                    <div className="col-11 col-sm-4 text-center">
                        {language === 'ES' ?
                            <>
                                <p>Lo sentimos, no encontramos el producto que estás buscando.
                                <Link to="/listado-de-productos" className="leti-btn">Visita nuestra página de productos</Link></p>
                            </>
                            :
                            <>
                                <p>Sorry, we couldn't find the page you're looking for.
                                <Link to="/listado-de-productos" className="leti-btn">Visit our products page</Link></p>
                            </>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default ProductNotFound
