import React, {useState, useEffect} from 'react'
import {getInfoBioletisan, updateBioletisanInfoTitle, updateBioletisanInfoSubTitle, addInfoItem} from '../../../../services/ApiClient'
import {useFormState} from '../../../../hooks/useFormState.js'
import Button from '../../../Form/FormButton/FormButton'
import EditInfoDataModal from './EditInfoDataModal/EditInfoDataModal'
import {app} from '../../../../services/firebase'

import {Editor} from '@tinymce/tinymce-react'
import InputFile from '../../../Form/InputFile/InputFile'
import InputWithLabel from '../../../Form/InputWithLabel/InputWithLabel'

function EditInfo() {

    const [infoData, setInfoData] = useState()
    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')
    const [messageSub, setMessageSub] = useState('')
    const [bool, setBool] = useState(false)
    const [modalData, setModalData] = useState([])
    const [newItemMessage, setNewItemMessage] = useState([])
    const [titleInfo, setTitleInfo] = useState('')
    const [titleInfoEng, setTitleInfoEng] = useState('')
    const [subTitleInfo, setSubTitleInfo] = useState('')
    const [subTitleInfoEng, setSubTitleInfoEng] = useState('')
    const [imageSuccess, setImageSuccess] = useState('')
    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)

    const {state, onChange} = useFormState(
        {
            data: {
                id: '',
                title: '',
                title_eng: '',
                subtitle: '',
                subtitle_eng: '',
                icon_url: '',
                item_description: '',
                item_description_eng: '',
                item_tag: "",
                item_tag_eng: "",
            },
            error: {
                title: true,
                subtitle: true,
                subtitle_eng: true,
                item_description: true,
                title_eng: true,
                item_description_eng: true,
                icon_url: true,
                item_tag: true,
                item_tag_eng: true
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            subtitle: v => v.length,
            subtitle_eng: v => v.length,
            icon_url: v => v.length,
            item_description: v => v.length,
            item_description_eng: v => v.length,
            item_tag: v => v.length,
            item_tag_eng: v => v.length,
        }
    )

    const {data, error} = state

    const handleInfoDescription = (e) => {
        data.item_description = e.target.getContent()
        error.item_description = false
    }

    const handleInfoDescriptionEng = (e) => {
        data.item_description_eng = e.target.getContent()
        error.item_description_eng = false
    }

    const handleInfoTitle = (e) => {
        setMessage('')
        data.title = e.target.getContent()
        error.title = false
    }

    const handleInfoTitleEng = (e) => {
        setMessage('')
        data.title_eng = e.target.getContent()
        error.title_eng = false
    }

    const handleInfoSubTitle = (e) => {
        setMessage('')
        data.subtitle = e.target.getContent()
        error.subtitle = false
    }

    const handleInfoSubTitleEng = (e) => {
        setMessage('')
        data.subtitle_eng = e.target.getContent()
        error.subtitle_eng = false
    }

    const deleteItem = (data) => {
        setInfoData(data)
        setBool(!bool)
    }

    const showModal = (data) => {
        setModalData(data)
        setBool(!bool)
    }

    const updateTitle = async (event) => {
        event.preventDefault()
        if (error.title === false && error.title_eng === false) {
            try {
                await updateBioletisanInfoTitle(data)
                    .then(info => {
                        setTitleInfo(info[0]?.title)
                        setTitleInfoEng(info[0]?.title_eng)
                        setMessage('Título actualizado exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite el título en ambos idiomas')
        }
    }

    const updateSubTitle = async (event) => {
        event.preventDefault()
        if (error.subtitle === false && error.subtitle_eng === false) {
            try {
                await updateBioletisanInfoSubTitle(data)
                    .then(info => {
                        setSubTitleInfo(info[0]?.subtitle)
                        setSubTitleInfoEng(info[0]?.subtitle_eng)
                        setMessageSub('Subtítulo actualizado exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessageSub('Por favor edite el subtítulo en ambos idiomas')
        }
    }

    const createInfoItem = async (event) => {
        event.preventDefault()

        data.title = infoData[0].title
        data.title_eng = infoData[0].title_eng
        data.subtitle = infoData[0].subtitle
        data.subtitle_eng = infoData[0].subtitle_eng

        if (error.icon_url === false && error.item_description === false && error.item_description_eng === false && error.item_tag === false && error.item_tag_eng === false) {
            try {
                await addInfoItem(data)
                    .then(goal => {
                        setInfoData(goal)
                        setNewItemMessage('Elemento creado exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setNewItemMessage('Por favor rellene todos los campos')
        }
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]
        if (file.size > 500000) {
            setImageSuccess('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setImageSuccess('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setImageSuccess("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.icon_url = fileUrl
            setIsDisabled(false)
            error.icon_url = false
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getInfoData = await getInfoBioletisan()
            setInfoData(getInfoData)
            setTitleInfo(getInfoData[0]?.title)
            setTitleInfoEng(getInfoData[0]?.title_eng)
            setSubTitleInfo(getInfoData[0]?.subtitle)
            setSubTitleInfoEng(getInfoData[0]?.subtitle_eng)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {bool && <EditInfoDataModal hideModal={() => setBool(!bool)} element={modalData} deleteItem={(updateData) => deleteItem(updateData)} />}
            <section className="container-fluid Letilabs EditContent EditContent-timeline">
                <h2>Editar beneficios</h2>
                <div className="row justify-content-around">
                    {infoData?.map(el =>
                        <div className="col-sm-3 col-12 EditCarousel__edit EditCarousel__edit-force" onClick={() => showModal(el)}>
                            <figure style={{width: "60px", height: "60px", borderRadius: "100px", background: "#0e95f2", display: "flex", alignItems: "center", margin: "auto"}}>
                                <img src={el?.icon_url} className="mt-3 mb-3" style={{width: "30px", margin: "auto", display: "flex"}} alt={el?.item_description}/>
                            </figure>
                            <p dangerouslySetInnerHTML={{__html: el?.item_description}} />
                        </div>
                    )}
                </div>
            </section>
            <section className="container-fluid EditContent">
                <h2>Bloque beneficios Bioletisan</h2>
                <form className="AdminEdit__form" onSubmit={updateTitle}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Editar título
                            </p>
                            <Editor
                                initialValue={titleInfo}
                                onChange={handleInfoTitle}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Edit title
                            </p>
                            <Editor
                                initialValue={titleInfoEng}
                                onChange={handleInfoTitleEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Button type="submit" cssStyle="leti-btn">Editar título</Button>
                            {message && <span className="AdminEdit__message ">{message}</span>}
                        </div>
                    </div>
                    <hr className="mt-5 mb-5" />
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
                <form className="AdminEdit__form" onSubmit={updateSubTitle}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Editar subtítulo
                            </p>
                            <Editor
                                initialValue={subTitleInfo}
                                onChange={handleInfoSubTitle}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Edit subtitle
                            </p>
                            <Editor
                                initialValue={subTitleInfoEng}
                                onChange={handleInfoSubTitleEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Button type="submit" cssStyle="leti-btn">Editar subtítulo</Button>
                            {messageSub && <span className="AdminEdit__message ">{messageSub}</span>}
                        </div>
                    </div>
                    <hr className="mt-5 mb-5" />
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
                <form className="AdminEdit__form" onSubmit={createInfoItem}>
                    <div className="row">
                        <h3>Añadir nuevo beneficio</h3>
                        <div className="col-12">
                            <p className="AdminEdit__form__label">
                                Icono
                            </p>
                            <InputFile
                                value={data?.icon_url}
                                onChange={onFileSelected}
                                id="fileButton"
                                name="picpath"
                                type="file"
                                placeholder="Selecciona una imagen"
                            />
                            {imageSuccess && <span className="AdminEdit__message mt-1">{imageSuccess}</span>}
                        </div>
                        {
                            fileSizeMessage &&
                            <div className="col-12">
                                <small>{fileSizeMessage}</small>
                            </div>
                        }
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.item_tag}
                                label="Tag"
                                onChange={onChange}
                                name="item_tag"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder=''
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputWithLabel
                                value={data.item_tag_eng}
                                label="Tag"
                                onChange={onChange}
                                name="item_tag_eng"
                                type="text"
                                cssStyle="form-control mb-5"
                                placeholder=''
                                en
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={data?.description}
                                onChange={handleInfoDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={data?.description_eng}
                                onChange={handleInfoDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Añadir nuevo beneficio</Button>
                            {newItemMessage && <span className="AdminEdit__message ">{newItemMessage}</span>}
                        </div>

                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditInfo