import React, {useState, useEffect} from 'react'
import {getGoalsBioletisan, updateBioletisanGoalsTitle, addGoalItem} from '../../../../services/ApiClient'
import {useFormState} from '../../../../hooks/useFormState.js'
import Button from '../../../Form/FormButton/FormButton'
import DeleteItemModal from './DeleteItemModal/DeleteItemModal'
import {app} from '../../../../services/firebase'

import {Editor} from '@tinymce/tinymce-react'
import InputFile from '../../../Form/InputFile/InputFile'

function EditGoals() {

    const [goalsData, setGoalsData] = useState()
    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')
    const [bool, setBool] = useState(false)
    const [modalData, setModalData] = useState([])
    const [newItemMessage, setNewItemMessage] = useState([])
    const [titleGoals, setTitleGoals] = useState('')
    const [titleGoalsEng, setTitleGoalsEng] = useState('')
    const [imageSuccess, setImageSuccess] = useState('')
    const [fileSizeMessage, setFileSizeMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)

    const {state} = useFormState(
        {
            data: {
                id: '',
                title: '',
                title_eng: '',
                icon_url: '',
                description: '',
                description_eng: ''
            },
            error: {
                title: true,
                description: true,
                title_eng: true,
                description_eng: true,
                icon_url: true
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            icon_url: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
        }
    )

    const {data, error} = state

    const handlePortfolioDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handlePortfolioDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    const handleGoalsTitle = (e) => {
        setMessage('')
        data.title = e.target.getContent()
        error.title = false
    }

    const handleGoalsTitleEng = (e) => {
        setMessage('')
        data.title_eng = e.target.getContent()
        error.title_eng = false
    }

    const deleteItem = (data) => {
        setGoalsData(data)
        setBool(!bool)
    }

    const showModal = (data) => {
        setModalData(data)
        setBool(!bool)
    }

    const updateTitle = async (event) => {
        event.preventDefault()
        if (error.title === false && error.title_eng === false) {
            try {
                await updateBioletisanGoalsTitle(data)
                    .then(info => {
                        setTitleGoals(info[0]?.title)
                        setTitleGoalsEng(info[0]?.title_eng)
                        setMessage('Título actualizado exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setMessage('Por favor edite el título en ambos idiomas')
        }
    }

    const createGoalItem = async (event) => {
        event.preventDefault()

        data.title = goalsData[0].title
        data.title_eng = goalsData[0].title_eng

        if (error.icon_url === false && error.description === false && error.description_eng === false) {
            try {
                await addGoalItem(data)
                    .then(goal => {
                        setGoalsData(goal)
                        setNewItemMessage('Elemento creado exitosamente')
                    })
                    .catch(error => {
                        setRegisterError(error)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }
        } else {
            setNewItemMessage('Por favor rellene todos los campos')
        }
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]
        if (file.size > 500000) {
            setImageSuccess('')
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            setImageSuccess('')
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    setImageSuccess("Imagen subida correctamente")
                })
                .catch(err => {console.log(err)})

            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.icon_url = fileUrl
            setIsDisabled(false)
            error.icon_url = false
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const getGoalsData = await getGoalsBioletisan()
            setGoalsData(getGoalsData)
            setTitleGoals(getGoalsData[0]?.title)
            setTitleGoalsEng(getGoalsData[0]?.title_eng)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {bool && <DeleteItemModal hideModal={() => setBool(!bool)} element={modalData} deleteItem={(updateData) => deleteItem(updateData)} />}
            <section className="container-fluid Letilabs EditContent EditContent-timeline">
                <h2>Editar características</h2>
                <div className="row justify-content-around">
                    {goalsData?.map(el =>
                        <div className="col-sm-3 col-12 EditCarousel__edit EditCarousel__edit-force" onClick={() => showModal(el)}>
                            <img src={el?.icon_url} className="mt-3 mb-3" style={{width: "60px", margin: "auto", display: "flex"}} alt="Edit" />
                            <p dangerouslySetInnerHTML={{__html: el?.description}} />
                        </div>
                    )}
                </div>
            </section>
            <section className="container-fluid EditContent">
                <h2>Bloque caraterísticas Bioletisan</h2>
                <form className="AdminEdit__form" onSubmit={updateTitle}>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Editar título
                            </p>
                            <Editor
                                initialValue={titleGoals}
                                onChange={handleGoalsTitle}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Edit title
                            </p>
                            <Editor
                                initialValue={titleGoalsEng}
                                onChange={handleGoalsTitleEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Button type="submit" cssStyle="leti-btn">Editar título</Button>
                            {message && <span className="AdminEdit__message ">{message}</span>}
                        </div>
                    </div>
                    <hr className="mt-5 mb-5" />
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
                <form className="AdminEdit__form" onSubmit={createGoalItem}>
                    <div className="row">
                        <h3>Añadir nueva característica</h3>
                        <div className="col-12">
                            <p className="AdminEdit__form__label">
                                Icono
                            </p>
                            <InputFile
                                value={data?.icon_url}
                                onChange={onFileSelected}
                                id="fileButton"
                                name="picpath"
                                type="file"
                                placeholder="Selecciona una imagen"
                            />
                            {imageSuccess && <span className="AdminEdit__message mt-1">{imageSuccess}</span>}
                        </div>
                        {
                            fileSizeMessage &&
                            <div className="col-12">
                                <small>{fileSizeMessage}</small>
                            </div>
                        }
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label">
                                Descripción
                            </p>
                            <Editor
                                initialValue={data?.description}
                                onChange={handlePortfolioDescription}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="AdminEdit__form__label en">
                                Description
                            </p>
                            <Editor
                                initialValue={data?.description_eng}
                                onChange={handlePortfolioDescriptionEng}
                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                init={{
                                    height: 140,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'bold',
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Button cssStyle="leti-btn AdminEdit__form-leti-btn" >Añadir nuevo elemento</Button>
                            {newItemMessage && <span className="AdminEdit__message ">{newItemMessage}</span>}
                        </div>

                    </div>
                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                </form>
            </section>
        </>
    )
}

export default EditGoals