import React, {useState, useEffect} from 'react'
import {getPlaceBioletisan} from '../../../../services/ApiClient.js'
import {Helmet} from 'react-helmet'

import EditInfoDataModal from './EditInfoDataModal/EditInfoDataModal.jsx'

function EditPlaces() {

    const [places, setPlaces] = useState([])
    const [modalData, setModalData] = useState([])
    const [bool, setBool] = useState(false)

    const showModal = (data) => {
        setModalData(data)
        setBool(!bool)
    }

    const deleteItem = (data) => {
        setPlaces(data)
        setBool(!bool)
    }

    useEffect(() => {
        const fetchData = async () => {
            const getPlaceData = await getPlaceBioletisan()
            setPlaces(getPlaceData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>Grupo LETI | Administrador Bioletisan</title>
            </Helmet>
            {bool && <EditInfoDataModal hideModal={() => setBool(!bool)} element={modalData} deleteItem={(updateData) => deleteItem(updateData)} />}
            <section className="container-fluid Letilabs EditContent EditContent-timeline">
                <h2>Editar proveedores</h2>
                <div className="row justify-content-around">
                    {places?.map(el =>
                        <div className="col-sm-3 col-12 EditCarousel__edit EditCarousel__edit-force" onClick={() => showModal(el)}>
                            <figure>
                                <img src={el?.place_url} className="mt-3 mb-3" style={{width: "100px", margin: "auto", display: "flex"}} alt="proveedor Bioletisan" />
                            </figure>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default EditPlaces

