import React, {useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {deleterFooterItem, updateFooterData} from '../../../../../services/ApiClient'
import {useFormState} from '../../../../../hooks/useFormState'
import './DeleteItemModal.css'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'

function DeleteItemModal({deleteItem, element, hideModal}) {

    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: element.id,
                title: element.title,
                title_eng: element.title_eng,
                link: element.link,
                description: element.description,
                description_eng: element?.description_eng,
            },
            error: {
                title: true,
                title_eng: true,
                link: true,
                description: true,
                description_eng: true,
            },
            touch: {},
        },
        {
            title: v => v.length,
            title_eng: v => v.length,
            link: v => v.length,
            description: v => v.length,
            description_eng: v => v.length,
        }
    )

    const {data, error} = state

    const deleteFooterItem = async (id) => {
        const updateData = await deleterFooterItem(id)
        deleteItem(updateData)
    }

    const updateFooterItem = async (id) => {

        if (error.title === false || error.title_eng === false || error.description === false || error.link === false || error.description_eng === false) {
            data.id = id
            try {
                await updateFooterData(data, id)
                    .then(updateData => {
                        deleteItem(updateData)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }

        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const handleBannerDescription = (e) => {
        data.description = e.target.getContent()
        error.description = false
    }

    const handleBannerDescriptionEng = (e) => {
        data.description_eng = e.target.getContent()
        error.description_eng = false
    }

    return (
        <div className="EditElementsModal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-8 EditElementsModal__container">
                        <span className="EditElementsModal__close" onClick={hideModal}></span>
                        <div className="col-sm-12">
                            <h1 className="DeleteItemModal__ask">Editar <br/>{data?.title}</h1>
                            <div className="card">
                                <div className="card-body EditElementsModal__body">
                                    <div className="row align-items-center">
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className="col-12 col-sm-6">
                                                    <p className="AdminEdit__form__label">
                                                        Editar título
                                                    </p>
                                                    <InputWithLabel
                                                        value={data?.title}
                                                        onChange={onChange}
                                                        name="title"
                                                        type="text"
                                                        cssStyle="form-control"
                                                        placeholder={element?.title}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <p className="AdminEdit__form__label en">
                                                        Edit title
                                                    </p>
                                                    <InputWithLabel
                                                        value={data?.title_eng}
                                                        onChange={onChange}
                                                        name="title_eng"
                                                        type="text"
                                                        cssStyle="form-control"
                                                        placeholder={element?.title_eng}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <p className="AdminEdit__form__label">
                                                        Editar descripción
                                                    </p>
                                                    <Editor
                                                        initialValue={data?.description}
                                                        onChange={handleBannerDescription}
                                                        apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                        init={{
                                                            height: 200,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist autolink lists link image',
                                                                'charmap print preview anchor help',
                                                                'searchreplace visualblocks code',
                                                                'insertdatetime media table paste wordcount'
                                                            ],
                                                            toolbar:
                                                                'bold',
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <p className="AdminEdit__form__label en">
                                                        Edit description
                                                    </p>
                                                    <Editor
                                                        initialValue={data?.description_eng}
                                                        onChange={handleBannerDescriptionEng}
                                                        apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                        init={{
                                                            height: 200,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist autolink lists link image',
                                                                'charmap print preview anchor help',
                                                                'searchreplace visualblocks code',
                                                                'insertdatetime media table paste wordcount'
                                                            ],
                                                            toolbar:
                                                                'bold',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div onClick={() => updateFooterItem(element._id)} className="leti-btn mt-0">Editar elemento</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div onClick={() => deleteFooterItem(element._id)} className="leti-btn mt-0 delete">Eliminar elemento</div>
                                        </div>
                                        <div className="col-12">
                                            {registerError && <div className="alert alert-danger">{registerError}</div>}
                                            {message && <span className="AdminEdit__message m-0">{message}</span>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DeleteItemModal
