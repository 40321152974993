import './RRSS.css'
import React, {useState, useEffect} from 'react'
import {getSocialBioletisan} from '../../../../../services/ApiClient'

import {useAuthContext} from '../../../../../contexts/AuthContext'


function RRSS() {

    const [socialData, setSocialData] = useState([])

    const {language} = useAuthContext()

    useEffect(() => {

        const fetchData = async () => {
            const getSocialData = await getSocialBioletisan()
            setSocialData(getSocialData[0])
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container LandingRRSS" style={{background: `url(${socialData?.background}) no-repeat center bottom 30% / cover`}} id='contactanos'>
            <div className="row">
                <div className='col-sm-3 col-12'>
                    <h1 dangerouslySetInnerHTML={{__html: language === 'ES' ? socialData?.title : socialData?.title_eng}} />
                    <h2 dangerouslySetInnerHTML={{__html: language === 'ES' ? socialData?.description : socialData?.description_eng}} />
                    <a href={`https://www.instagram.com/${socialData.link}`} target='_blank' rel="noreferrer">@{socialData?.link}</a>
                </div>
            </div>
        </section>
    )
}

export default RRSS
