import './WhoWeAre.css'
import React, {useState, useEffect} from 'react'
import {useAuthContext} from '../../../../../contexts/AuthContext'
import {getWhoWeAre} from '../../../../../services/ApiClient'
import {Fade} from 'react-awesome-reveal'

function WhoWeAre() {

    const [whoWeAreData, setWhoWeAreData] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getWhoWeAreData = await getWhoWeAre()
            setWhoWeAreData(getWhoWeAreData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container-fluid BiocontrolledInfo-WhoWeAre">
                <div className='row justify-content-center'>
                    <div className='col-sm-6 col-12'>
                        <Fade>
                            <div className='BiocontrolledInfo-Logo' />
                        </Fade>
                        <Fade cascade damping={0.9} direction="up" triggerOnce>
                            <h1>{language === 'ES' ? whoWeAreData?.title : whoWeAreData?.title_eng}</h1>
                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? whoWeAreData?.description : whoWeAreData?.description_eng}} />
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhoWeAre
